import Portal from '~/components/Portal/Portal';
/** sp */
import CreatePasswordSidePage from '~/components/Blocks/Shared/CreatePassword/CreatePasswordSidePage';
import MyAppealsSidePage from '~/components/MyAppealsSidePage/MyAppealsSidePage';
import AuthSP from './AuthSP/AuthSP';
import CallbackSP from './CallbackSP/CallbackSP';
import ConnectionSP from './ConnectionSP/ConnectionSP';
import VacationShutdownSP from './VacationShutdownSP/VacationShutdownSP';
import MobileSBP from '~/components/Blocks/Templates/Payment/MobileSBP/MobileSBP';
import ProlongationSP from './ProlongationSP/ProlongationSP';
import SoftlineDescriptionSP from './SoftlineSPs/SoftlineDescriptionSP/SoftlineDescriptionSP';
import SoftlineSubscribeSP from './SoftlineSPs/SoftlineSubscribeSP/SoftlineSubscribeSP';
import SoftlineUnsubscribeSP from './SoftlineSPs/SoftlineUnsubscribeSP/SoftlineUnsubscribeSP';

export const SidepPages = (): JSX.Element => {
  return (
    <Portal wrapperId="portalPrimary">
      <ProlongationSP />
      <SoftlineDescriptionSP />
      <SoftlineSubscribeSP />
      <SoftlineUnsubscribeSP />
      <VacationShutdownSP />
      <ConnectionSP />
      <CallbackSP />
      <MyAppealsSidePage />
      <AuthSP />
      <CreatePasswordSidePage />
      <MobileSBP />
    </Portal>
  );
};
