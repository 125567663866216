/** библиотеки */
import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import Image from 'next/image';
import { observer } from 'mobx-react';

/** компоненты библиотеки */
import {
  H2,
  Text,
  Snoska,
  Icons,
  Input,
  Button,
  defaultTheme,
  OptionProp,
  Checkbox,
  H3,
  Select,
  Tabs,
  SidePage,
  Link,
} from 'cordis-core-ui-planeta';

/** стили */
import {
  StyledCheckboxWrapper,
  StyledPaymentX2Form,
  StyledQrSidePage,
} from './style';

/** константы */
import {
  ERROR_MESSAGES,
  NEW_CARD,
  PAYMENT_OPTION,
  CHECK_URL_AMOUNT,
  PAYMENT_TYPE_INTERFACE,
  CARD_TEXT,
  SBP_TEXT,
  CHECK_PAYMENT_SLUG,
  CONTRACT_NOT_FOUND_TYPE,
} from './constants';
import { PAYMENT_TYPE } from '~/interfaces/PromoInterface';

import {
  desktop1280,
  desktop940,
  desktop500,
  desktop380,
} from '~/components/Grid/constants';
import { NEW_ACCOUNT } from '../PaymentResult/constants';
import { DOCUMENTS_LINK } from '../../Shared/Autopayment/AutoPaymentWizard/constants';
import { OPEN_PAYMENT_SP } from '~/components/AuthWizard/constants';

/** типы */
import { PaymentFormProps } from './types';

/** api */
import {
  setRegisterCardPaymentWithCardBinding,
  setRegisterOrderForContractByBindingPaymentCard,
  setCardPaymentRegisterOrder,
} from '~/api/api';

/** утилиты */
import { checkUrlAccess } from './utils';

/** компоненты */
import Promo from './Promo/Promo';
import Portal from '~/components/Portal/Portal';
import PinWizard from '../../Shared/PinWizard/PinWizard';
import ListOfSbpBanks from '../../Shared/ListOfSbpBanks/ListOfSbpBanks';
import CardAndSbpPaymentSelect from '../../Shared/CardAndSbpPaymentSelect/CardAndSbpPaymentSelect';

/** hooks */
import { usePayment } from './PaymentHook';
import { formatNumber, removeLeadingZero } from '~/utils/utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileSBPStore from './MobileSBP/store/useMobileSBPStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
import usePinWizardStore from '../../Shared/PinWizard/store/usePinWizardStore';

/**
 * Компонент формы пополнения баланса X2
 * @param {PaymentFormProps} result Флаг отображения результатов и успешности операции
 * @param {PaymentFormProps} setResult dispatch результата оплаты
 * @param {PaymentFormProps} paymentStyle стиль отображения блока
 */
const PaymentX2Form: React.FC<PaymentFormProps> = ({
  setResult,
  paymentStyle,
  promoListContent,
  activePromotion,
  setActivePromotion,
  getPromoInfo,
  setAnswer,
  cardLimit,
  sbpLimit,
}) => {
  const {
    paymentInfoStore: {
      isLoading: isLoadingPaymentInfo,
      haveLinkedCards,
      haveLinkedSbpBindings,
      isCardsError,
      isSbpError,
    },
    authStore: { auth, isAuth, isLoadingAuth },
  } = useRootStore();
  const { isOpenSBPCards, setIsUseSBPBanksList } = useMobileSBPStore();
  const {
    openSPAfterAuthorizationState,
    setOpenSPAfterAuthorizationState,
  } = useMakeAuthStore();

  const {
    setIsShowPinSidePage,
    setContractNumberForPin,
    token,
    isShowPinSidePage,
  } = usePinWizardStore();

  // Для чтения параметров из url
  const router = useRouter();
  // Номер договора ошибка
  const [contractNumberError, setContractNumberError] = useState<boolean>(
    false,
  );
  // Сумма ошибка
  const [amountError, setAmountError] = useState<boolean>(false);
  // Привязать платёжные данные
  const [isRememberPaymentData, setIsRememberPaymentData] = useState<boolean>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    isAuth && !isSBP,
  );
  // Выбранная карта в select
  const [selectedCard, setSelectedCard] = useState<OptionProp | null>(null);
  // Выбранный счёт в select
  const [
    selectedSbpAccount,
    setSelectedSbpAccount,
  ] = useState<OptionProp | null>(null);

  // Вычисление ширины экрана
  const isDesktop380 = useMediaQuery({
    query: `(min-width: ${desktop380}px)`,
  });
  const isDesktop500 = useMediaQuery({
    query: `(min-width: ${desktop500}px)`,
  });
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  // tabs с иконками и текстом
  const tabsList = [
    {
      value: isDesktop940 ? CARD_TEXT.BANK_CARD : CARD_TEXT.BY_CARD,
      icon: <Icons.WhiteCardIcon />,
      inactiveIcon: <Icons.SmallCardIcon />,
    },
    {
      value: SBP_TEXT.SBP,
      icon: <Icons.ColorSbpIcon />,
    },
  ];

  // Оплата картой
  const payByCreditCard = async (newAmount?: number) => {
    fetchAmount.current = CHECK_URL_AMOUNT;
    const baseUrl = `${window.location.origin}/${CHECK_PAYMENT_SLUG}`;

    // Если пользователь выбирает "Новая карта" или у него нет привязанных карт, но стоит checkbox "запомнить карту"
    const isNewCard =
      (selectedCard?.label === NEW_CARD || !haveLinkedCards) &&
      isRememberPaymentData;

    // Если оплачивают привязанной картой
    const isLinkedCard = selectedCard && selectedCard?.label !== NEW_CARD;

    // Вариант оплаты
    const paymentOption = () => {
      switch (true) {
        case isNewCard &&
          (isAuth ||
            !!(token || window.sessionStorage.getItem(contractNumber))):
          return PAYMENT_OPTION.LINK_NEW_CARD_IS_AUTH;
        case isNewCard &&
          !token &&
          !window.sessionStorage.getItem(contractNumber):
          return PAYMENT_OPTION.LINK_NEW_CARD;
        case isLinkedCard:
          return PAYMENT_OPTION.LINKED_CARD_CONTRACT_NUMBER;
        case !isRememberPaymentData:
          return PAYMENT_OPTION.NEW_CARD_WITHOUT_BINDING;
        default:
          return null;
      }
    };

    // Привязка карты у не авторизованного клиента или другому номеру договору
    if (paymentOption() === PAYMENT_OPTION.LINK_NEW_CARD) {
      // открывается сп пин-кода
      setIsShowPinSidePage(true);
    }

    // Если привязывает карту с авторизацией или по временному токену
    if (paymentOption() === PAYMENT_OPTION.LINK_NEW_CARD_IS_AUTH) {
      try {
        const registerCardPaymentWithCardBinding = await setRegisterCardPaymentWithCardBinding(
          newAmount ?? +amount.forSend,
          encodeURIComponent(
            `${baseUrl}?hasCardBinding=${true}&size=2&contractNumber=${contractNumber}&amount=${
              newAmount ?? +amount.forSend
            }`,
          ),
          token ?? window.sessionStorage.getItem(contractNumber),
        );
        if (registerCardPaymentWithCardBinding.authCode === 'Ok') {
          await checkUrlAccess(
            registerCardPaymentWithCardBinding.formUrl,
            fetchAmount,
            setResult,
            null,
            setIsLoading,
          );
        } else {
          setErrorMessage(ERROR_MESSAGES.ERROR);
        }
      } catch (error) {
        setErrorMessage(ERROR_MESSAGES.ERROR);
      }
    }

    // Если оплачивает привязанной картой номер договора
    if (paymentOption() === PAYMENT_OPTION.LINKED_CARD_CONTRACT_NUMBER) {
      try {
        const registerOrderByBindingPaymentCard = await setRegisterOrderForContractByBindingPaymentCard(
          contractNumber,
          newAmount ?? +amount.forSend,
          encodeURIComponent(
            `${baseUrl}?size=2&contractNumber=${contractNumber}&amount=${
              newAmount ?? +amount.forSend
            }&linkedCardValue=${selectedCard.value}`,
          ),
          selectedCard.value,
        );

        if (registerOrderByBindingPaymentCard.authCode === 'None') {
          await checkUrlAccess(
            registerOrderByBindingPaymentCard.formUrl,
            fetchAmount,
            setResult,
            null,
            setIsLoading,
          );
        } else {
          setErrorMessage(ERROR_MESSAGES.ERROR);
        }
      } catch (error) {
        const err = error.errorMessage ? JSON.parse(error.errorMessage) : {};
        if (err.Type === CONTRACT_NOT_FOUND_TYPE) {
          setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
          return;
        }
        setErrorMessage(ERROR_MESSAGES.ERROR);
      }
    }

    // Оплачивает новой картой без привязки
    if (paymentOption() === PAYMENT_OPTION.NEW_CARD_WITHOUT_BINDING) {
      try {
        const paymentRegisterOrder = await setCardPaymentRegisterOrder(
          contractNumber,
          newAmount ?? +amount.forSend,
          encodeURIComponent(
            `${baseUrl}?size=2&contractNumber=${contractNumber}&amount=${
              newAmount ?? +amount.forSend
            }`,
          ),
        );
        if (paymentRegisterOrder.authCode === 'None') {
          await checkUrlAccess(
            paymentRegisterOrder.formUrl,
            fetchAmount,
            setResult,
            null,
            setIsLoading,
          );
        } else {
          setErrorMessage(ERROR_MESSAGES.ERROR);
        }
      } catch (error) {
        const err = error.errorMessage ? JSON.parse(error.errorMessage) : {};
        if (err.Type === CONTRACT_NOT_FOUND_TYPE) {
          setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
          return;
        }
        setErrorMessage(ERROR_MESSAGES.ERROR);
      }
    }
    setActivePromotion(null);
    setOpenSPAfterAuthorizationState(null);
    setIsLoading(false);
  };

  // Валидация
  const handleCheckIsValid = (): boolean => {
    if (!contractNumber) {
      setContractNumberError(true);
      setErrorMessage(ERROR_MESSAGES.CONTRACT_NUMBER);
      return false;
    }

    const isCardAmountError =
      isCardTab && cardLimit.minAmount && +amount.forSend < cardLimit.minAmount;
    const isSbpAmountError =
      isSbpTab && sbpLimit.minAmount && +amount.forSend < sbpLimit.minAmount;

    if (isCardAmountError || isSbpAmountError) {
      setAmountError(true);
      setErrorMessage(
        `Сумма менее ${
          isCardAmountError ? cardLimit.minAmount : sbpLimit.minAmount
        } рублей`,
      );
      return false;
    }

    if (haveLinkedCards && !selectedCard && !isSBP) {
      setErrorMessage(ERROR_MESSAGES.CARD_NOT_FOUND);
      return false;
    }

    // Если карта просрочена
    if (haveLinkedCards && selectedCard?.bottomText && !isSBP) {
      setErrorMessage(ERROR_MESSAGES.CARD_HAS_EXPIRED);
      return false;
    }

    setErrorMessage('');
    return true;
  };

  const {
    contractNumber,
    setContractNumber,
    amount,
    errorMessage,
    setErrorMessage,
    isLoading,
    setIsLoading,
    fetchAmountRef: fetchAmount,
    isSidePageOpen,
    QRcode,
    activeTabIndex,
    setActiveTabIndex,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    paymentViaSBP,
    isSBP,
    isAvailableSBP,
    handleOnChangeInputMoney,
    snoska,
    tabsIcons,
    paymentMethodData,
    onCloseClickSbpDesktop,
    isOpenPromo,
    setIsOpenPromo,
    payHandler,
    convertedSbpBanksList,
    isCardTab,
    isSbpTab,
  } = usePayment({
    paymentStyle,
    payByCreditCard,
    handleCheckIsValid,
    isRememberPaymentData,
    selectedSbpAccount,
    promoListContent,
    activePromotion,
    setActivePromotion,
    getPromoInfo,
    cardLimit,
    sbpLimit,
  });

  /** Произвести оплату после авторизации */
  const isPaymentAfterAuthorization = useMemo(
    () => openSPAfterAuthorizationState === OPEN_PAYMENT_SP && isAuth,
    [openSPAfterAuthorizationState, isAuth],
  );

  useEffect(() => {
    if (
      (token ||
        isPaymentAfterAuthorization ||
        window.sessionStorage.getItem(contractNumber)) &&
      !isSBP
    ) {
      payByCreditCard();
    }
  }, [token, isPaymentAfterAuthorization]);

  // Способы оплаты tabs
  const tabs = isDesktop380 ? tabsList : tabsIcons;

  // Отображение select
  const paymentMethodDataFormed = paymentMethodData.map((item) => {
    let itemLabel = '';
    switch (true) {
      case !isDesktop380:
        itemLabel = item.value === '1' ? SBP_TEXT.SBP : CARD_TEXT.BY_CARD;
        break;
      case !isDesktop500:
        itemLabel =
          item.value === '1'
            ? SBP_TEXT.TOP_UP_VIA_SBP
            : CARD_TEXT.TOP_UP_WITH_A_CARD;
        break;
      default:
        itemLabel =
          item.value === '1'
            ? SBP_TEXT.TOP_UP_ACCOUNT_VIA_SPB
            : CARD_TEXT.TOP_UP_ACCOUNT_WITH_A_BANK_CARD;
    }
    return {
      ...item,
      label: itemLabel,
    };
  });

  // Выключает иконку загрузки при появлении ошибки
  useEffect(() => {
    if (!errorMessage) return;
    setIsLoading(false);
    fetchAmount.current = CHECK_URL_AMOUNT;
  }, [errorMessage]);

  // Выключает иконку загрузки при закрытии СП ввода пин-кода
  useEffect(() => {
    if (isShowPinSidePage) return;
    setIsLoading(false);
  }, [isShowPinSidePage]);

  // При вводе номера договора
  const handleOnChangeInputText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue.length > 8 || (newValue && /\D/g.test(newValue))) return;
    setContractNumber(newValue);
    setContractNumberForPin(newValue);
    setIsRememberPaymentData(e.target.value === auth.contractName);
  };

  useEffect(() => {
    if (!haveLinkedCards) setSelectedCard(null);
  }, [haveLinkedCards]);

  useEffect(() => {
    if (!haveLinkedSbpBindings) setSelectedSbpAccount(null);
  }, [haveLinkedSbpBindings]);

  // Checkbox привязки платёжной информации
  const handleRememberBankCard = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsRememberPaymentData(e.target.checked);
  };

  // Checkbox
  const checkbox = (
    <Checkbox checked={isRememberPaymentData} onChange={handleRememberBankCard}>
      {isSBP ? 'Привязать счёт СБП' : 'Запомнить карту'}
    </Checkbox>
  );

  // Нет селектора карт или счетов СБП
  const hasNoCardOrSBP =
    (!selectedCard && !isSBP) || (!selectedSbpAccount && isSBP);

  // Не СБП и выбрана новая карта
  const isNewCard = selectedCard?.label === NEW_CARD && !isSBP;

  // СБП и выбран новый счёт
  const isNewSbpAccount = selectedSbpAccount?.label === NEW_ACCOUNT && isSBP;

  const showCheckbox = hasNoCardOrSBP || isNewCard || isNewSbpAccount;
  const showSbpSelector =
    haveLinkedSbpBindings && !isLoadingPaymentInfo && isSBP;
  const showCardsSelector = haveLinkedCards && !isLoadingPaymentInfo && !isSBP;
  const showSelector = showCardsSelector || showSbpSelector;

  /**
   * Обработка авторизации
   */
  useEffect(() => {
    setIsRememberPaymentData(isAuth && !isSBP);
    const contractName = isAuth
      ? auth.contractName
      : (router?.query?.contractNameFromShortLink as string) ?? '';
    setContractNumber(contractName);
    setContractNumberForPin(contractName);
  }, [isAuth]);

  useEffect(() => {
    setIsRememberPaymentData(isAuth && !isSBP);
  }, [isSBP]);

  /** Обработка ошибки загрузки привязанных карт/счетов СБП */
  useEffect(() => {
    if (isCardsError) setErrorMessage(ERROR_MESSAGES.ERROR_LINKED_CARD);
    if (isSbpError) setErrorMessage(ERROR_MESSAGES.ERROR_LINKED_ACCOUNTS);
  }, [isCardsError, isSbpError]);

  return (
    <StyledPaymentX2Form isOpenSBPCards={isOpenSBPCards}>
      {!paymentStyle && (
        <div className="payment-form__header">
          {isDesktop1280 ? (
            <H2>Банковской картой</H2>
          ) : (
            <H3>Банковской картой</H3>
          )}
          <div className="payment-form__header__svg">
            <Icons.MirIcon />
            <Icons.VisaIcon />
            <Icons.MastercardIcon />
          </div>
        </div>
      )}
      {paymentStyle?.type === PAYMENT_TYPE_INTERFACE.SELECT && (
        <Select
          className="payment-form__payment-method-select"
          onOptionClick={(option) => setSelectedPaymentMethod(option)}
          value={selectedPaymentMethod ? selectedPaymentMethod.value : ''}
          data={paymentMethodDataFormed}
          width="388px"
        />
      )}
      {paymentStyle?.type === PAYMENT_TYPE_INTERFACE.TABS && (
        <div className="payment-form__payment-method-tabs">
          <Tabs
            valueWithIcons={tabs}
            onChange={(tabIndex) => {
              setActiveTabIndex(tabIndex);
              setErrorMessage('');
            }}
            activeTabIndex={activeTabIndex}
          />
        </div>
      )}
      <Portal>
        <SidePage
          show={isSidePageOpen}
          headerText="Пополнить счёт через СБП"
          onCloseClick={onCloseClickSbpDesktop}
        >
          <StyledQrSidePage>
            <div className="qr__code-block">
              <div>
                <Image
                  src={`data:image/png;base64, ${QRcode}`}
                  alt="qrcode"
                  width={212}
                  height={212}
                  quality={100}
                />
              </div>
              <div className="qr__text-block">
                <Text lineHeight="24px">
                  Отсканируйте QR-код камерой телефона
                  <br />
                  и&nbsp;завершите оплату в&nbsp;мобильном приложении банка.
                </Text>
              </div>
            </div>
            {isRememberPaymentData && (
              <Snoska className="qr__snoska">
                Сканируя QR-код, вы&nbsp;подтверждаете согласие с&nbsp;условиями
                привязки счёта, указанными в{'\u00A0'}
                <Link href={DOCUMENTS_LINK} target="_blank">
                  Регламенте
                </Link>
                .
                <br />
                Вы&nbsp;будете перенаправлены в&nbsp;приложение банка.
              </Snoska>
            )}
          </StyledQrSidePage>
        </SidePage>
        {activePromotion && promoListContent[activePromotion] && (
          <SidePage
            show={isOpenPromo}
            // eslint-disable-next-line dot-notation
            headerText={promoListContent[activePromotion].header}
            onCloseClick={() => {
              setIsOpenPromo(false);
              setActivePromotion(null);
            }}
            width={
              promoListContent[activePromotion]?.isCashbackBottoms
                ? '832px'
                : null
            }
          >
            <Promo
              // eslint-disable-next-line dot-notation
              content={promoListContent[activePromotion].content}
              payByCreditCard={payByCreditCard}
              paymentViaSBP={paymentViaSBP}
              contractName={contractNumber}
              amount={+amount.forSend}
              paymentPromoType={activePromotion}
              setAnswer={setAnswer}
              setIsOpenPromo={setIsOpenPromo}
              setIsLoading={setIsLoading}
              // eslint-disable-next-line dot-notation
              isCashbackBottoms={
                promoListContent[activePromotion]?.isCashbackBottoms
              }
              currentPaymentType={
                isSBP ? PAYMENT_TYPE.SBP : PAYMENT_TYPE.CARD_PAYMENT
              }
            />
          </SidePage>
        )}
      </Portal>
      {isSBP && !isAvailableSBP ? (
        <div className="payment-form__wrapper__errorBlock">
          <div className="payment-form__wrapper">
            <div className="payment-form__wrapper__block">
              <Text>Оплата через СБП временно недоступна.</Text>
            </div>
          </div>
        </div>
      ) : (
        <>
          {(!isDesktop940 || isDesktop1280 || (!isAuth && !isLoadingAuth)) && (
            <Text lineHeight="24px" className="payment-form__text">
              Средства поступят на счёт через несколько секунд. Без комиссии.
            </Text>
          )}
          <div className="payment-form__wrapper__errorBlock">
            <div className="payment-form__wrapper">
              <div className="payment-form__wrapper__block">
                <Text
                  className="payment-form__wrapper__block-text"
                  color={defaultTheme.colors.shadow}
                >
                  Номер договора
                </Text>
                <Input
                  type="text"
                  placeholder="до 8 цифр"
                  inputMode="numeric"
                  value={removeLeadingZero(contractNumber)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    handleOnChangeInputText(e);
                  }}
                  width="100%"
                  borderRadius="4px"
                  error={contractNumberError}
                  onFocus={() => setContractNumberError(false)}
                />
              </div>
              <div className="payment-form__wrapper__block payment-form__input-wrapper_money">
                <Text
                  className="payment-form__wrapper__block-text"
                  color={defaultTheme.colors.shadow}
                >
                  Сумма
                </Text>
                <Input
                  type="money"
                  placeholder="500 &#8381;"
                  value={amount.value}
                  onChangeCustomInput={handleOnChangeInputMoney}
                  error={amountError}
                  onFocus={() => setAmountError(false)}
                  width="100%"
                />
              </div>
              {showCardsSelector && (
                <div className="payment-form__wrapper__block payment-form__wrapper__block__card-block-half">
                  <Text
                    className="payment-form__wrapper__block-text"
                    color={defaultTheme.colors.shadow}
                  >
                    Мои карты
                  </Text>
                  <CardAndSbpPaymentSelect
                    className="payment-form__wrapper__block-card"
                    setSelectedAccount={setSelectedCard}
                    convertedSbpBanksList={convertedSbpBanksList}
                    isBankCard
                    setError={setErrorMessage}
                  />
                </div>
              )}
              {showSbpSelector && (
                <div className="payment-form__wrapper__block payment-form__wrapper__block__card-block-half">
                  <Text
                    className="payment-form__wrapper__block-text"
                    color={defaultTheme.colors.shadow}
                  >
                    Мои счета
                  </Text>
                  <CardAndSbpPaymentSelect
                    className="payment-form__wrapper__block-card"
                    setSelectedAccount={setSelectedSbpAccount}
                    convertedSbpBanksList={convertedSbpBanksList}
                    isSbp
                    setError={setErrorMessage}
                  />
                </div>
              )}
            </div>
            {errorMessage ? (
              <div className="payment-form__wrapper__text">
                <Text color={defaultTheme.colors.planeta}>{errorMessage}</Text>
              </div>
            ) : (
              <Text
                className="payment-form__wrapper__text"
                color={defaultTheme.colors.gray}
                lineHeight="24px"
              >
                Сумма {isDesktop940 && 'платежа'} от{' '}
                {isCardTab
                  ? formatNumber(cardLimit.minAmount)
                  : formatNumber(sbpLimit.minAmount)}{' '}
                до{' '}
                {isCardTab
                  ? formatNumber(cardLimit.maxAmount)
                  : formatNumber(sbpLimit.maxAmount)}{' '}
                {isDesktop940 ? 'рублей' : '₽'}
              </Text>
            )}
          </div>
        </>
      )}
      <div className="payment-form__button-wrapper">
        {showCheckbox && (
          <StyledCheckboxWrapper $showSelector={showSelector}>
            {checkbox}
          </StyledCheckboxWrapper>
        )}
        <Button
          loading={isLoading || isLoadingPaymentInfo}
          onClick={() => {
            setIsUseSBPBanksList(isSBP && isRememberPaymentData);
            payHandler();
          }}
          disabled={isSBP && !isAvailableSBP}
        >
          Оплатить
        </Button>
        {snoska()}
      </div>

      <Portal>
        <PinWizard />
        <ListOfSbpBanks />
      </Portal>
    </StyledPaymentX2Form>
  );
};

export default observer(PaymentX2Form);
