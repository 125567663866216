/** libraries */
import { useRouter } from 'next/router';
import {
  useMemo,
  useEffect,
  useState,
  useRef,
  SetStateAction,
  Dispatch,
} from 'react';
import { isMobile } from 'react-device-detect';
/** api */
import {
  getRecommendAutoPaymentInfo,
  cardPaymentBindAutoPayment,
  setRegisterCardPaymentWithCardBinding,
  getSbpPaymentCheckOrderState,
  getSbpPaymentCheckOrderByBindingState,
  sbpRegisterBinding,
  sbpCheckBinding,
  checkCardPaymentStateForCardBinding,
  checkCardPaymentOrderState,
  setCardPaymentRegisterOrder,
  setRegisterOrderForContractByBindingPaymentCard,
  checkAuth,
} from '~/api/api';
/** constants */
import { VIEW_TYPES } from '~/components/Blocks/Shared/Autopayment/AutoPaymentWizard/constants';
import { AUTOPAYMENT_MIN_AMOUNT } from '../../Shared/Autopayment/constants';
import {
  ERROR_MESSAGES,
  CHECK_URL_AMOUNT,
  CHECK_PAYMENT_SLUG,
  PAYMENT_OPTION,
  DEFAULT_AMOUNT,
  DEFAULT_FORM_ERROR_STATE,
  CONTRACT_NOT_FOUND_TYPE,
} from '../Payment/constants';
import { PAYMENT_QP, RESULT_STATE } from './constants';
/** interfaces */
import { AmountProps, StateFormErrorProps } from '../AutoPayment/types';
import {
  SbpInfo,
  SbpBindingCheckInfoProps,
  LimitProps,
  SbpRegisterBindingInfo,
} from './interfaces';
/** utils */
import { checkUrlAccess, checkUrlAccessMobile } from '../Payment/utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileSBPStore from '../Payment/MobileSBP/store/useMobileSBPStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
import usePinWizardStore from '../../Shared/PinWizard/store/usePinWizardStore';
import { OPEN_PAYMENT_SP } from '~/components/AuthWizard/constants';

interface UsePaymentResultReturnProps {
  resultHeader: string;
  sbpBindingCheckInfo: SbpBindingCheckInfoProps;
  contractNumber: string;
  state: RESULT_STATE;
  bind: () => void;
  timeIsUp: boolean;
  setTimeIsUp: Dispatch<SetStateAction<boolean>>;
  sbpBindingInfo: SbpInfo | SbpRegisterBindingInfo;
  orderId: string | string[];
  token: string;
  linkedAccount: string;
  cardBindingId: number;
  linkedCardValue: string;
  setAutoPaymentAmount: Dispatch<SetStateAction<AmountProps>>;
  getAutoPaymentAmount: () => AmountProps;
  stateFormError: StateFormErrorProps;
  setIsAutoCount: Dispatch<SetStateAction<boolean>>;
  bindAutoPayment: (
    idAccount?: number,
    contractToken?: string,
    contractNameFromPaymentCheck?: string,
  ) => void;
  viewType: VIEW_TYPES;
  setTimeIsUpFirstBind: Dispatch<SetStateAction<boolean>>;
  amount: string;
  payByCreditCard: () => Promise<void>;
  isLoadingRepeatPayment: boolean;
  errorMessage: string;
  isCard: boolean;
}

interface UsePaymentResultProps {
  cardLimit: LimitProps;
  sbpLimit: LimitProps;
}

export const usePaymentResult = ({
  cardLimit,
  sbpLimit,
}: UsePaymentResultProps): UsePaymentResultReturnProps => {
  const {
    authStore: { isAuth, auth },
    paymentInfoStore: { getSbpBindings, getPaymentCards },
    autoPaymentInfoStore: {
      autoPaymentData,
      getAutoPaymentData,
      setAutoPaymentData,
    },
  } = useRootStore();
  const {
    isOpenSBPCards,
    setIsOpenSBPCards,
    fetchAmount,
    setFetchAmount,
    setPayload,
    setIsUseSBPBanksList,
    isZeroing,
    resetStore,
  } = useMobileSBPStore();
  const {
    openSPAfterAuthorizationState,
    setOpenSPAfterAuthorizationState,
  } = useMakeAuthStore();
  const {
    setIsShowPinSidePage,
    setContractNumberForPin,
    isPinAuth,
  } = usePinWizardStore();

  const router = useRouter();

  /** Состояние карточки */
  const [state, setState] = useState<RESULT_STATE>(
    RESULT_STATE.WaitingForPayment,
  );
  /** СБП */
  const [qrId, setQrId] = useState<string[] | string>('');
  /** Карта */
  const [orderId, setOrderId] = useState<string[] | string>('');
  /** Запомнить карту/сбп счёт */
  const [isRememberPaymentData, setIsRememberPaymentData] = useState<boolean>(
    null,
  );
  const [isCorrect, setIsCorrect] = useState<boolean>(null);
  /** Договор */
  const [contractNumber, setContractNumber] = useState<string>('');
  // Статус оплаты СБП
  const [isCorrectSbpStatus, setIsCorrectSbpStatus] = useState<boolean>(null);
  /** Данные о привязке счёта */
  const [sbpBindingInfo, setSbpBindingInfo] = useState<
    SbpInfo | SbpRegisterBindingInfo
  >(null);
  /** Результат привязки счёта сбп */
  const [
    sbpBindingCheckInfo,
    setSbpBindingCheckInfo,
  ] = useState<SbpBindingCheckInfoProps>(null);
  // id таймера для проверки платежа по СБП
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  // id таймера для проверки привязки по СБП
  const [timerIdBind, setTimerIdBind] = useState<NodeJS.Timeout>();
  /** Результат привязки счёта */
  const [isCorrectBinding, setIsCorrectBinding] = useState<boolean>(null);
  /** Привязка карты */
  const [hasCardBinding, setHasCardBinding] = useState<boolean>(false);
  /** id привязанной карты */
  const [linkedCardValue, setLinkedCardValue] = useState<string>('');
  /** сумма оплаты */
  const [amount, setAmount] = useState<string>('');
  /** Загрузка повторной оплаты */
  const [isLoadingRepeatPayment, setIsLoadingRepeatPayment] = useState<boolean>(
    false,
  );
  /** ошибка повторной оплаты */
  const [errorMessage, setErrorMessage] = useState<string>('');
  /** id счёта с которого производится оплата */
  const [linkedAccount, setLinkedAccount] = useState<string>(null);
  /** id привязываемой карты */
  const [cardBindingId, setCardBindingId] = useState<number>(null);
  /** Время qr кода истекло */
  const [timeIsUpFirstBind, setTimeIsUpFirstBind] = useState<boolean>(false);
  /** Сумма автоплатежа */
  const [autoPaymentAmount, setAutoPaymentAmount] = useState<AmountProps>(
    DEFAULT_AMOUNT,
  );
  const [isAutoCount, setIsAutoCount] = useState<boolean>(false);
  /** Оплата картой */
  const [isCard, setIsCard] = useState<boolean>(null);
  // Флаг подключения автоплатежа на договоре
  const isAutoPaymentOn = autoPaymentData?.isActive;
  // Код шаблона отображения по умолчанию
  const getDefaultView: number = isAutoPaymentOn
    ? VIEW_TYPES.UNBIND
    : VIEW_TYPES.BIND;
  // Код текущего шаблона отображения
  const [viewType, setViewType] = useState<VIEW_TYPES>(getDefaultView);
  // Флаг вызова авто оплаты
  const [isSubmitTriggered, setIsSubmitTriggered] = useState<boolean>(false);
  const [stateFormError, setFormErrorState] = useState<StateFormErrorProps>(
    DEFAULT_FORM_ERROR_STATE,
  );
  const fetchAmountRef = useRef<number>(CHECK_URL_AMOUNT);

  const redirectToCheckPayment = (correct?: boolean) =>
    /** TODO: задача https://ctms.itmh.ru/browse/DEPIT2JS-472 */
    router.push(
      `/${CHECK_PAYMENT_SLUG}?isCorrect=${correct}`,
      `/${CHECK_PAYMENT_SLUG}?isCorrect=${correct}`,
    );

  /** Время qr кода истекло */
  const [timeIsUp, setTimeIsUp] = useState<boolean>(false);

  /** Авторизационный токен */
  const token = useMemo(() => {
    if (isAuth) return '';
    return contractNumber ? window.sessionStorage.getItem(contractNumber) : '';
  }, [contractNumber, isPinAuth, isAuth]);

  /** Минимальное значение первого платежа */
  const minAmountValue = useMemo(
    () => (isCard ? cardLimit.minAmount : sbpLimit.minAmount),
    [isCard],
  );

  /** Подключает/изменяет автоплатёж для договора */
  const cardPaymentBind = async (
    cardBindId: number,
    amountCard: number,
    isAutoPayment: boolean,
    isSbp?: boolean,
  ) => {
    setErrorMessage(null);
    try {
      if (router.query.contractNumber) {
        const paymentCheck = await getRecommendAutoPaymentInfo(
          router.query.contractNumber as string,
        );

        if (!paymentCheck.isAutoPaymentBindRecommended) return;
      }

      const res = await cardPaymentBindAutoPayment(
        cardBindId,
        amountCard,
        isAutoPayment,
        token,
      );
      if (isSbp) {
        await getSbpBindings();
      }
      setViewType(VIEW_TYPES.BIND_SUCCESS);
      setAutoPaymentData(res);
    } catch (e) {
      const error = JSON.parse(e?.errorMessage);
      if (error.Type === CONTRACT_NOT_FOUND_TYPE) {
        setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
        return;
      }
      setErrorMessage(ERROR_MESSAGES.ERROR);
      setViewType(VIEW_TYPES.BIND_FAILED);

      if (router.query?.bindAutoPayment)
        router.replace(
          {
            query: { ...router.query, bindAutoPayment: 'failed' },
          },
          undefined,
          {
            shallow: true,
          },
        );

      if (error?.Type === 'CardPaymentException') {
        setErrorMessage(ERROR_MESSAGES.AUTOPAYMENT_IS_ALREADY_ACTIVATED);
      }
    }
  };

  /** Проверка карты при подключении автоплатежа */
  const bindAutoPayment = (
    idAccount?: number,
    contractToken?: string,
    contractNameFromPaymentCheck?: string,
  ) => {
    setIsSubmitTriggered(true);
    const errorState = validateForm();
    if (errorState.amount.error) {
      return;
    }
    try {
      if (idAccount) bindingWithCard(idAccount);
      if (!idAccount && contractNameFromPaymentCheck)
        bindingWithoutCard(contractToken, contractNameFromPaymentCheck);
    } catch (error) {
      setErrorMessage(ERROR_MESSAGES.ERROR_BIND_CARD);
    }
  };

  /** Подключение автоплатежа с привязанной картой */
  const bindingWithCard = async (id?: number, isSbp?: boolean) => {
    try {
      await cardPaymentBind(
        id,
        (autoPaymentAmount.forSend as unknown) as number,
        isAutoCount,
        isSbp,
      );
    } catch (e) {
      setErrorMessage(ERROR_MESSAGES.ERROR);
    }
  };
  /** Подключение автоплатежа с привязкой карты */
  const bindingWithoutCard = async (
    contractToken?: string,
    contractNameFromPaymentCheck?: string,
  ) => {
    const { href, search } = window.location;
    const baseUrl = search ? href.slice(0, href.indexOf(search)) : href;
    const getBackUrl = () => {
      if (!contractNameFromPaymentCheck)
        return `${baseUrl}?contractNumber=${
          auth.contractName
        }&isAutoPayment=${true}&amount=${
          autoPaymentAmount.forSend
        }&hasAutoCalc=${isAutoCount}&showAutopayment=${true}`;

      return `${baseUrl}?contractNumber=${contractNameFromPaymentCheck}&hasCardBinding=${true}&amount=${
        autoPaymentAmount.forSend
      }&bindAutoPayment=${true}&hasAutoCalc=${isAutoCount}`;
    };

    try {
      const registerCardPaymentWithCardBinding = await setRegisterCardPaymentWithCardBinding(
        minAmountValue,
        encodeURIComponent(getBackUrl()),
        contractToken,
      );
      if (registerCardPaymentWithCardBinding.authCode === 'Ok') {
        window.location.assign(registerCardPaymentWithCardBinding.formUrl);
      }
    } catch (e) {
      setErrorMessage(ERROR_MESSAGES.ERROR);
    }
  };
  /** Валидация данных */
  const validateForm = (): StateFormErrorProps => {
    const minPaymentAmount =
      autoPaymentData?.minPayment ?? AUTOPAYMENT_MIN_AMOUNT;
    const isCorrectAmount =
      Number(autoPaymentAmount?.forSend) >= minPaymentAmount;

    return {
      amount: {
        error: !isCorrectAmount,
      },
    };
  };
  useEffect(() => {
    if (isSubmitTriggered) {
      const errorState = validateForm();

      setFormErrorState(errorState);
    }
  }, [autoPaymentAmount, isSubmitTriggered]);

  const handleContractNumber = () => {
    const qpContractNumber = router.query?.[
      PAYMENT_QP.contractNumber
    ] as string;
    if (router.query?.[PAYMENT_QP.contractNumber]) {
      setContractNumber(qpContractNumber);
      setContractNumberForPin(qpContractNumber);
    }
  };

  const handleLinkedAccount = () => {
    if ((router.query?.[PAYMENT_QP.linkedAccount] as string) !== 'null') {
      setLinkedAccount(router.query?.[PAYMENT_QP.linkedAccount] as string);
    }
  };

  const handleSbpPaymentCheckOrderState = () => {
    if (router.query?.[PAYMENT_QP.qrId]) {
      setIsCard(false);
      if ((router.query?.[PAYMENT_QP.linkedAccount] as string) !== 'null') {
        sbpPaymentCheckOrderByBindingState(
          +(router.query?.[PAYMENT_QP.linkedAccount] as string),
          router.query?.[PAYMENT_QP.qrId] as string,
        );
      } else {
        sbpPaymentCheckOrderState(
          router.query?.[PAYMENT_QP.contractNumber] as string,
          router.query?.[PAYMENT_QP.qrId] as string,
        );
      }
      if (
        router.query?.[PAYMENT_QP.isCorrect] === 'true' &&
        (router.query?.[PAYMENT_QP.linkedAccount] as string) === 'null' &&
        state !== RESULT_STATE.AutopaymentSuggestion
      ) {
        setState(
          (router.query?.[PAYMENT_QP.isLinkedAccount] as string) === 'true'
            ? RESULT_STATE.AutopaymentSuggestion
            : RESULT_STATE.SbpBindingSuggestion,
        );
      }
      setQrId(router.query?.[PAYMENT_QP.qrId]);
    }
  };

  /** Проверка платежа с привязкой карты */
  const handleCheckPaymentOrderState = () => {
    if (router.query?.[PAYMENT_QP.planetaOrderId]) {
      setIsCard(true);
      setOrderId(router.query?.[PAYMENT_QP.planetaOrderId]);
      if (
        router.query?.[PAYMENT_QP.hasCardBinding] === 'true' &&
        (token || isAuth) &&
        autoPaymentData
      ) {
        setHasCardBinding(true);
        checkPaymentStateForCardBinding(
          router.query?.[PAYMENT_QP.planetaOrderId] as string,
        );
      }
    }
  };

  /* Проверка платежа без привязки карты */
  const handleCheckPaymentOrderStateWithoutBinding = () => {
    if (!router.query?.[PAYMENT_QP.planetaOrderId]) return;
    setOrderId(router.query?.[PAYMENT_QP.planetaOrderId]);

    if (
      router.query?.[PAYMENT_QP.hasCardBinding] !== 'true' &&
      router.query?.[PAYMENT_QP.contractNumber]
    ) {
      checkPaymentOrderState(
        router.query?.[PAYMENT_QP.contractNumber] as string,
        router.query?.[PAYMENT_QP.planetaOrderId] as string,
      );
    }
  };

  const handleIsRememberPaymentData = () => {
    if (router.query?.[PAYMENT_QP.isRememberPaymentData])
      setIsRememberPaymentData(
        router.query?.[PAYMENT_QP.isRememberPaymentData] === 'true',
      );
  };

  const handleCheckSbpBinding = () => {
    if (
      router.query?.[PAYMENT_QP.qrId] &&
      router.query?.[PAYMENT_QP.isRememberPaymentData] &&
      router.query?.[PAYMENT_QP.sbpSubscriptionId] !== 'undefined'
    ) {
      setIsCard(false);
      if (router.query?.[PAYMENT_QP.isCorrect] === 'true') {
        setState(RESULT_STATE.WaitingForBinding);
      }
      checkSbpBinding(router.query?.[PAYMENT_QP.sbpSubscriptionId] as string);
    }
  };

  const handleIsCorrect = () => {
    if (router.query?.[PAYMENT_QP.isCorrect]) {
      setIsCorrect(router.query?.[PAYMENT_QP.isCorrect] === 'true');
    }
  };

  const handleLinkedCardValue = () => {
    if ((router.query?.[PAYMENT_QP.linkedCardValue] as string) !== 'null') {
      setLinkedCardValue(router.query?.[PAYMENT_QP.linkedCardValue] as string);
    }
  };

  const handleAmount = () => {
    if (router.query?.[PAYMENT_QP.amount]) {
      setAmount(router.query?.[PAYMENT_QP.amount] as string);
    }
  };

  useEffect(() => {
    if (!router.isReady) return;
    handleLinkedAccount();
    handleIsRememberPaymentData();
    handleContractNumber();
    handleIsCorrect();
    handleLinkedCardValue();
    handleAmount();
    handleSbpPaymentCheckOrderState();
    handleCheckPaymentOrderStateWithoutBinding();
  }, [router.query]);

  useEffect(() => {
    if (
      autoPaymentData &&
      isRememberPaymentData !== null &&
      state !== RESULT_STATE.AutopaymentSuggestion
    ) {
      handleCheckSbpBinding();
    }
  }, [router.query, autoPaymentData, isRememberPaymentData]);

  useEffect(() => {
    if (state === RESULT_STATE.AutopaymentSuggestion) return;

    if ([VIEW_TYPES.BIND_SUCCESS, VIEW_TYPES.BIND_FAILED].includes(viewType)) {
      setState(RESULT_STATE.AutopaymentSuggestion);
      return;
    }

    handleCheckPaymentOrderState();
  }, [router.query, token, autoPaymentData]);

  // Возвращает информацию о состоянии платежа с использованием СБП
  const sbpPaymentCheckOrderState = async (
    contractNumberProp: string,
    qr: string,
  ) => {
    try {
      const res = await getSbpPaymentCheckOrderState(contractNumberProp, qr);
      if (res.statusCode === 'Success') {
        setIsCorrectSbpStatus(true);
        if (isOpenSBPCards) setIsOpenSBPCards(false);
      }
    } catch (error) {
      const err = error.errorMessage ? JSON.parse(error.errorMessage) : {};
      if (err.Type === CONTRACT_NOT_FOUND_TYPE) {
        setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
        return;
      }
      console.error('sbpPaymentCheckOrderState', error);
    }
  };

  /** Возвращает информацию о состоянии платежа с использованием привязанного счёта СБП */
  const sbpPaymentCheckOrderByBindingState = async (
    paymentBindingId: number,
    QRId: string,
  ) => {
    try {
      const res = await getSbpPaymentCheckOrderByBindingState(
        paymentBindingId,
        QRId,
      );
      const needAutopayment = await getRecommendAutoPaymentInfo(
        router.query.contractNumber as string,
      );

      if (
        res.statusCode === 'Success' &&
        state !== RESULT_STATE.AutopaymentSuggestion
      )
        setState(
          !needAutopayment.isAutoPaymentBindRecommended
            ? RESULT_STATE.PaymentAndBindingSuccess
            : RESULT_STATE.AutopaymentSuggestion,
        );

      if (res.statusCode === 'Declined') {
        const isBinding = res.isAutoPaymentDeleted || res.isBindingDeleted;
        setState(
          isBinding
            ? RESULT_STATE.SbpBoundFailed
            : RESULT_STATE.SbpPaymentFailed,
        );
        setIsCorrectSbpStatus(false);
      }

      if (res.statusCode === 'InProgress')
        setTimeout(
          () => sbpPaymentCheckOrderByBindingState(paymentBindingId, QRId),
          2000,
        );
    } catch (e) {
      setState(RESULT_STATE.SbpPaymentFailed);
      setIsCorrectSbpStatus(false);
      console.error('sbpPaymentCheckOrderByBindingState', e);
    }
  };

  /** Действие по доступности адреса оплаты */
  const onUrlAccess = () => {
    setIsOpenSBPCards(true);
  };

  /** Регистрирует qr-код для оплаты с созданием подписки */
  const registerSbpPaymentWithBinding = async () => {
    try {
      const tokenFromStorage = window.sessionStorage.getItem(contractNumber);
      const res = await sbpRegisterBinding(token ?? tokenFromStorage);
      if (isMobile && res.payload) {
        setPayload(res.payload);
        setIsUseSBPBanksList(state !== RESULT_STATE.SbpPaymentFailed);
        await checkUrlAccessMobile(
          res.payload,
          fetchAmount,
          setFetchAmount,
          null,
          onUrlAccess,
        );
      }
      if (res.qrImage) setSbpBindingInfo(res as SbpRegisterBindingInfo);
    } finally {
      setOpenSPAfterAuthorizationState(null);
    }
  };

  // Проверка платежа СБП каждые 3 секунды
  useEffect(() => {
    if (sbpBindingInfo?.qrId && !isCorrectSbpStatus && !isMobile) {
      (async () => {
        const id = setInterval(() => {
          sbpPaymentCheckOrderState(contractNumber, sbpBindingInfo.qrId);
        }, 3000);
        setTimerId((id as unknown) as NodeJS.Timeout);
      })();
    }
    if (isMobile && timerId) clearInterval(timerId);
    if (sbpBindingInfo && isCorrectSbpStatus) {
      clearInterval(timerId);
    }
  }, [sbpBindingInfo, isCorrectSbpStatus]);

  // Проверка привязки СБП каждые 3 секунды
  useEffect(() => {
    if (
      (sbpBindingInfo?.qrId ||
        (router?.query?.sbpSubscriptionId &&
          (router?.query?.sbpSubscriptionId as string) !== 'undefined')) &&
      !sbpBindingCheckInfo &&
      autoPaymentData &&
      isRememberPaymentData !== null
    ) {
      (async () => {
        const id = setInterval(() => {
          checkSbpBinding(
            (sbpBindingInfo as SbpRegisterBindingInfo)?.subscriptionId ??
              (router?.query?.sbpSubscriptionId as string),
          );
        }, 3000);
        setTimerIdBind((id as unknown) as NodeJS.Timeout);
      })();
    }
    if (
      (sbpBindingInfo ||
        (router?.query?.sbpSubscriptionId as string) !== 'undefined') &&
      sbpBindingCheckInfo
    ) {
      setIsCorrectBinding(true);
      getSbpBindings();
      if (timerIdBind) clearInterval(timerIdBind);
      if (isOpenSBPCards) setIsOpenSBPCards(false);
    }

    return () => {
      if (timerIdBind) clearInterval(timerIdBind);
    };
  }, [
    sbpBindingInfo,
    sbpBindingCheckInfo,
    autoPaymentData,
    isRememberPaymentData,
  ]);

  /** Проверка статуса подписки */
  const checkSbpBinding = async (sbpSubscriptionId?: string) => {
    try {
      const res = await sbpCheckBinding(
        sbpSubscriptionId !== 'undefined'
          ? sbpSubscriptionId
          : (sbpBindingInfo as SbpInfo).sbpSubscriptionId,
      );
      if (res.sbpSubscriptionStatus === 'Subscribed') {
        setSbpBindingCheckInfo(res);
        await getSbpBindings();
        /** Автоплатеж подключён, оплачивали с привязкой */
        if (autoPaymentData?.isActive && isRememberPaymentData) {
          setState(RESULT_STATE.PaymentAndBindingSuccess);
          return;
        }
        setState(
          autoPaymentData?.isActive
            ? RESULT_STATE.ACCOUNT_LINKED_SUCCESS
            : RESULT_STATE.AutopaymentSuggestion,
        );
        setTimeIsUp(false);
      }
    } catch (e) {
      setState(RESULT_STATE.SbpBindingFailed);
      registerSbpPaymentWithBinding();
    }
  };

  /** Привязать счёт */
  const bind = () => {
    if (sbpBindingInfo) {
      setTimeIsUp(false);
      return;
    }
    setTimeIsUpFirstBind(false);
    if (!isAuth && !window.sessionStorage.getItem(contractNumber)) {
      setIsShowPinSidePage(true);
    } else {
      registerSbpPaymentWithBinding();
    }
  };

  /** Вышел таймер первой проверки привязки сбп счёта */
  useEffect(() => {
    if (timeIsUpFirstBind) setState(RESULT_STATE.SbpBindingFailed);
  }, [timeIsUpFirstBind]);

  /** Произвести оплату после авторизации */
  const isPaymentAfterAuthorization = useMemo(
    () => openSPAfterAuthorizationState === OPEN_PAYMENT_SP && isAuth,
    [openSPAfterAuthorizationState, isAuth],
  );

  useEffect(() => {
    if (state === RESULT_STATE.AutopaymentSuggestion) return;
    if ((token && isPinAuth) || isPaymentAfterAuthorization) {
      registerSbpPaymentWithBinding();
    }
  }, [token, isPaymentAfterAuthorization, isPinAuth]);

  /** Заголовок результата */
  const resultHeader = useMemo(() => {
    if (
      [
        RESULT_STATE.CardPaymentFailed,
        RESULT_STATE.SbpBindingFailed,
        RESULT_STATE.SbpBoundFailed,
        RESULT_STATE.SbpPaymentFailed,
      ].includes(state)
    ) {
      return 'Оплата не прошла :(';
    }

    if (state === RESULT_STATE.WaitingForPayment) return 'Ожидание оплаты...';

    if (
      (isRememberPaymentData && sbpBindingCheckInfo) ||
      ((router?.query?.hasCardBinding as string) === 'true' && orderId)
    ) {
      return `Оплата и привязка ${
        sbpBindingCheckInfo ? 'счёта' : 'карты'
      } прошли успешно!`;
    }
    /** Оплата с привязкой сбп счёта */
    if (
      (isCorrectSbpStatus &&
        isRememberPaymentData &&
        qrId &&
        sbpBindingCheckInfo) ||
      isCorrectBinding
    ) {
      return 'Счёт привязан успешно!';
    }
    /** Оплата без привязки */
    if (isCorrect || orderId) {
      return 'Оплата прошла успешно!';
    }
    /** Не удалось привязать счёт сбп */
    if (!isCorrectSbpStatus && isRememberPaymentData && qrId) {
      return 'Не удалось привязать счёт';
    }
    return '';
  }, [
    isCorrectSbpStatus,
    isRememberPaymentData,
    qrId,
    isCorrectBinding,
    isCorrect,
    router,
    state,
  ]);

  // Проверит информацию о состоянии платежа, если привязывали карту
  const checkPaymentStateForCardBinding = async (id: string) => {
    try {
      const bindCard = await checkCardPaymentStateForCardBinding(id, token);
      await getPaymentCards();
      if (bindCard.authCode === 'Ok' && router.query.bindAutoPayment) {
        cardPaymentBind(
          bindCard.cardBindingId,
          Number(router.query.amount as string),
          (router.query.hasAutoCalc as unknown) as boolean,
          null,
        );
        return;
      }

      if (bindCard.authCode === 'Ok') {
        setState(
          autoPaymentData?.isActive
            ? RESULT_STATE.PaymentAndBindingSuccess
            : RESULT_STATE.AutopaymentSuggestion,
        );
        setCardBindingId(bindCard.cardBindingId);
      } else {
        setState(RESULT_STATE.CardPaymentFailed);
      }
    } catch (error) {
      setState(RESULT_STATE.CardPaymentFailed);
    }
  };

  // Проверит информацию о состоянии платежа без привязки карты
  const checkPaymentOrderState = async (contractNum: string, id: string) => {
    try {
      const orderState = await checkCardPaymentOrderState(contractNum, id);
      if (
        orderState.authCode === 'Ok' &&
        state !== RESULT_STATE.AutopaymentSuggestion
      ) {
        const isRecommendAutoPayment = await getRecommendAutoPaymentInfo(
          contractNum,
        );
        setState(
          !isRecommendAutoPayment.isAutoPaymentBindRecommended
            ? RESULT_STATE.PaymentAndBindingSuccess
            : RESULT_STATE.AutopaymentSuggestion,
        );
      } else if (state !== RESULT_STATE.AutopaymentSuggestion) {
        setState(RESULT_STATE.CardPaymentFailed);
      }
    } catch (error) {
      const err = error.errorMessage ? JSON.parse(error.errorMessage) : {};
      if (err.Type === CONTRACT_NOT_FOUND_TYPE) {
        setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
      }
      setState(RESULT_STATE.CardPaymentFailed);
    }
  };

  /** Сбрасываем состояния, при закрытии СП MobileSBP */
  useEffect(() => {
    if (isZeroing) {
      setSbpBindingInfo(null);
      resetStore();
    }
  }, [isZeroing]);

  // Оплата картой
  const payByCreditCard = async () => {
    fetchAmountRef.current = CHECK_URL_AMOUNT;
    const baseUrl = `${window.location.origin}/${CHECK_PAYMENT_SLUG}`;

    // Если пользователь выбирает "Новая карта" или у него нет привязанных карт, но стоит checkbox "запомнить карту"
    const isNewCard = orderId && hasCardBinding && !linkedCardValue;

    // Если оплачивают привязанной картой
    const isLinkedCard = orderId && linkedCardValue;

    // Вариант оплаты
    const paymentOption = () => {
      switch (true) {
        case isNewCard && (isAuth || !!token):
          return PAYMENT_OPTION.LINK_NEW_CARD_IS_AUTH;
        case !!isLinkedCard:
          return PAYMENT_OPTION.LINKED_CARD_CONTRACT_NUMBER;
        case !hasCardBinding:
          return PAYMENT_OPTION.NEW_CARD_WITHOUT_BINDING;
        default:
          return null;
      }
    };

    // Если привязывает карту с авторизацией
    if (paymentOption() === PAYMENT_OPTION.LINK_NEW_CARD_IS_AUTH) {
      try {
        const registerCardPaymentWithCardBinding = await setRegisterCardPaymentWithCardBinding(
          +amount,
          encodeURIComponent(
            `${baseUrl}?hasCardBinding=${true}&size=2&contractNumber=${contractNumber}&amount=${+amount}`,
          ),
          token,
        );
        if (registerCardPaymentWithCardBinding.authCode === 'Ok') {
          await checkUrlAccess(
            registerCardPaymentWithCardBinding.formUrl,
            fetchAmountRef,
            redirectToCheckPayment,
            null,
            setIsLoadingRepeatPayment,
          );
        } else {
          setErrorMessage(ERROR_MESSAGES.ERROR);
        }
      } catch (error) {
        setErrorMessage(ERROR_MESSAGES.ERROR);
      }
    }

    // Если оплачивает привязанной картой номер договора
    if (paymentOption() === PAYMENT_OPTION.LINKED_CARD_CONTRACT_NUMBER) {
      try {
        const registerOrderByBindingPaymentCard = await setRegisterOrderForContractByBindingPaymentCard(
          contractNumber,
          +amount,
          encodeURIComponent(
            `${baseUrl}?contractNumber=${contractNumber}&size=2&contractNumber=${contractNumber}&amount=${+amount}&linkedCardValue=${linkedCardValue}`,
          ),
          +linkedCardValue,
        );

        if (registerOrderByBindingPaymentCard.authCode === 'None') {
          await checkUrlAccess(
            registerOrderByBindingPaymentCard.formUrl,
            fetchAmountRef,
            redirectToCheckPayment,
            null,
            setIsLoadingRepeatPayment,
          );
        } else {
          setErrorMessage(ERROR_MESSAGES.ERROR);
        }
      } catch (error) {
        const err = error.errorMessage ? JSON.parse(error.errorMessage) : {};
        if (err.Type === CONTRACT_NOT_FOUND_TYPE) {
          setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
          return;
        }
        setErrorMessage(ERROR_MESSAGES.ERROR);
      }
    }

    // Оплачивает новой картой без привязки
    if (paymentOption() === PAYMENT_OPTION.NEW_CARD_WITHOUT_BINDING) {
      try {
        const paymentRegisterOrder = await setCardPaymentRegisterOrder(
          contractNumber,
          +amount,
          encodeURIComponent(
            `${baseUrl}?contractNumber=${contractNumber}&size=2&contractNumber=${contractNumber}&amount=${+amount}`,
          ),
        );
        if (paymentRegisterOrder.authCode === 'None') {
          await checkUrlAccess(
            paymentRegisterOrder.formUrl,
            fetchAmountRef,
            redirectToCheckPayment,
            null,
            setIsLoadingRepeatPayment,
          );
        } else {
          setErrorMessage(ERROR_MESSAGES.ERROR);
        }
      } catch (error) {
        const err = error.errorMessage ? JSON.parse(error.errorMessage) : {};
        if (err.Type === CONTRACT_NOT_FOUND_TYPE) {
          setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
          return;
        }
        setErrorMessage(ERROR_MESSAGES.ERROR);
      }
    }
  };

  const getAutoPaymentAmount = () => {
    if (autoPaymentAmount?.forSend) return autoPaymentAmount;

    return {
      value: `${router.query?.amount} \u20BD`,
      forSend: `${router.query?.amount}`,
    };
  };

  useEffect(() => {
    if (token) {
      (async () => {
        const res = await checkAuth(token);
        if (res.status !== 200) {
          window.sessionStorage.removeItem(contractNumber);
          return;
        }
        await getAutoPaymentData(token);
      })();
    }
  }, [token]);

  return {
    resultHeader,
    sbpBindingCheckInfo,
    contractNumber,
    state,
    bind,
    timeIsUp,
    setTimeIsUp,
    sbpBindingInfo,
    orderId,
    token,
    linkedAccount,
    cardBindingId,
    linkedCardValue,
    setAutoPaymentAmount,
    getAutoPaymentAmount,
    stateFormError,
    setIsAutoCount,
    bindAutoPayment,
    viewType,
    setTimeIsUpFirstBind,
    amount,
    payByCreditCard,
    isLoadingRepeatPayment,
    errorMessage,
    isCard,
  };
};
