/** библиотеки */
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
/** Компоненты библиотеки */
import { Icon, Icons, Loader, Text } from 'cordis-core-ui-planeta';
/** Стили */
import { IPv6StateWizardContainer } from './IPv6StateWizard.style';
/** Интерфейсы */
import { IPv6StateWizardProps } from './IPv6StateWizard.types';
/** api */
import { getIPv6Agreement } from '~/api/apiPab2c';
/* Константы */
import { VIEW_TYPES_IP_V6 } from '~/components/Blocks/Templates/Pab2c/Internet/IPv6StateWizard/constants';
import { OPEN_IPV6_SP_AGREEMENT } from '~/components/AuthWizard/constants';
/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
/** stores */
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

/**
 * Форма смены состояния договора.
 */
const IPv6StateWizard: FC<IPv6StateWizardProps> = ({
  sim,
  viewType,
  isIpV6Enabled,
  error,
  setError,
  setViewType,
  isIpv6StateLoading,
}: IPv6StateWizardProps) => {
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [textAgreement, setTextAgreement] = useState<string>(null);
  const { setOpenSPAfterAuthorizationState } = useMakeAuthStore();

  useEffect(() => {
    (async () => {
      try {
        const result = await getIPv6Agreement(sim, !isIpV6Enabled);
        setTextAgreement(result);
        setIsDataLoading(false);
        setOpenSPAfterAuthorizationState(null);
      } catch (e) {
        if (e.statusCode === 401) {
          setOpenSPAfterAuthorizationState(OPEN_IPV6_SP_AGREEMENT);
          return;
        }
        setOpenSPAfterAuthorizationState(null);
        setViewType(VIEW_TYPES_IP_V6.ERROR);
        setError(e?.message ?? 'Ошибка получения соглашения');
        setIsDataLoading(false);
      }
    })();
  }, [isIpV6Enabled]);

  return (
    <>
      <IPv6StateWizardContainer>
        {(isDataLoading || isIpv6StateLoading) && !error && <Loader />}

        {!isDataLoading && !isIpv6StateLoading && (
          <form className="wizard">
            <div className="wizard__wrapper">
              <div className="wizard__content">
                {viewType === VIEW_TYPES_IP_V6.FORM && (
                  <Text lineHeight="24px">{parseHtml(textAgreement)}</Text>
                )}

                {viewType === VIEW_TYPES_IP_V6.SUCCESS && (
                  <>
                    <div className="wizard__content-success">
                      <div className="success-icon">
                        <Icon icon={<Icons.SuccessIconAnimated />} />
                      </div>
                      <h2>IPv6 {isIpV6Enabled ? 'включён' : 'отключён'}!</h2>
                    </div>
                  </>
                )}

                {viewType === VIEW_TYPES_IP_V6.ERROR && (
                  <>
                    <div className="wizard__content-success">
                      <div className="success-icon">
                        <Icon icon={<Icons.NotOkBigIcon />} />
                      </div>
                      <h2>
                        IPv6 {!isIpV6Enabled ? 'не включён' : 'не отключён'}!
                      </h2>
                      <p>{error}</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>
        )}
      </IPv6StateWizardContainer>
    </>
  );
};

export default observer(IPv6StateWizard);
