/** libraries */
import { FC, useEffect, useRef, useState } from 'react';
import {
  LeadingText,
  SidePage,
  Snoska,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** styles */
import { StyledPinWizard } from './styles';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import {
  ERROR_MESSAGE,
  OPEN_PAYMENT_SP,
} from '~/components/AuthWizard/constants';
import { DEFAULT_ERROR } from './constants';
/** api */
import { authByPin } from '~/api/api';
/** stores */
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
import usePinWizardStore from './store/usePinWizardStore';

const PIN_NUMBERS_AMOUNT = 5;

const PinWizard: FC = () => {
  const { toggleVisible } = useMakeAuthStore();
  const {
    isShowPinSidePage,
    setIsShowPinSidePage,
    contractNumberForPin,
    setToken,
  } = usePinWizardStore();

  /** Закрытие сп */
  const zeroing = () => {
    setIsShowPinSidePage(false);
    setPinNumbers(Array(PIN_NUMBERS_AMOUNT).fill(''));
    setErrorMessage(null);
    setDefaultError('');
  };

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const inputRefs = useRef<Array<HTMLInputElement>>([]);
  const [pinNumbers, setPinNumbers] = useState<Array<string>>(
    Array(PIN_NUMBERS_AMOUNT).fill(''),
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [defaultError, setDefaultError] = useState<string>('');

  useEffect(() => {
    if (pinNumbers.every((number) => number !== '')) {
      pinAuth();
    } else {
      setErrorMessage(null);
    }
  }, [pinNumbers]);

  const handleInputChange = (index: number, value: string) => {
    if (
      value.length > 0 &&
      index < PIN_NUMBERS_AMOUNT - 1 &&
      inputRefs.current[index + 1]
    ) {
      inputRefs.current[index + 1].focus();
    }

    const newPinNumbers = [...pinNumbers];
    newPinNumbers[index] = value.slice(0, 1);
    setPinNumbers(newPinNumbers);
  };

  const handleInputKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.keyCode === 8 && index > 0 && pinNumbers[index] === '') {
      const newPinNumbers = [...pinNumbers];
      newPinNumbers[index - 1] = '';
      setPinNumbers(newPinNumbers);
      inputRefs.current[index - 1].focus();
      event.preventDefault();
    }
  };

  const pinAuth = async () => {
    try {
      const pin = pinNumbers.join('');
      const res = await authByPin(contractNumberForPin, pin);
      if (res.token) {
        window.sessionStorage.setItem(contractNumberForPin, res.token);
        setToken(res.token);
        zeroing();
      }
    } catch (e) {
      const error = e.errorMessage ? JSON.parse(e.errorMessage) : null;
      if (error.Type in ERROR_MESSAGE)
        setDefaultError(ERROR_MESSAGE[error.Type]);
      else setDefaultError(ERROR_MESSAGE[DEFAULT_ERROR]);
    }
  };

  /** Кнопка "ввести пароль" */
  const enterPassword = () => {
    toggleVisible(OPEN_PAYMENT_SP);
    setIsShowPinSidePage(false);
  };

  return (
    <SidePage
      show={isShowPinSidePage}
      headerText="Введите пин-код"
      onCloseClick={zeroing}
      isOnlyMobileFooter
      removeScrollBar
    >
      <StyledPinWizard isError={!!errorMessage}>
        {isDesktop940 ? (
          <LeadingText color={defaultTheme.colors.black}>
            Подтвердите, что {contractNumberForPin} — это ваш договор
          </LeadingText>
        ) : (
          <Text lineHeight="24px">
            Подтвердите, что {contractNumberForPin} — это ваш договор
          </Text>
        )}
        <div className="pincode">
          {pinNumbers.map((number, index) => (
            <input
              // для autofocus, но возможно есть красивое решение
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              value={number}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleInputKeyDown(index, e)}
              ref={(ref) => {
                inputRefs.current[index] = ref;
              }}
              type="number"
              inputMode="numeric"
              pattern="[0-9]"
              maxLength={1}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={index === 0}
            />
          ))}
        </div>
        {errorMessage && (
          <div className="error">
            <Text lineHeight="24px" color={defaultTheme.colors.planeta}>
              Неправильный ПИН-код
            </Text>
            <Snoska className="error__message">{errorMessage}</Snoska>
          </div>
        )}
        {defaultError && (
          <div className="error">
            <Text lineHeight="24px" color={defaultTheme.colors.planeta}>
              {defaultError}
            </Text>
          </div>
        )}
        <div className="links">
          <Text
            lineHeight="24px"
            color={defaultTheme.colors.planeta}
            onClick={enterPassword}
          >
            Ввести пароль
          </Text>
          {/* <Text lineHeight="24px" color={defaultTheme.colors.planeta}>
            Подтвердить по СМС или звонку
          </Text> */}
        </div>
      </StyledPinWizard>
    </SidePage>
  );
};

export default observer(PinWizard);
