/* eslint-disable no-nested-ternary */
/** библиотеки */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { isMobile } from 'react-device-detect';
import { useRouter } from 'next/router';

/** api */
import {
  checkAuth,
  getSbpPaymentCheckOrderState,
  getStaticSbpPaymentByContractName,
  sbpRegisterOrderByBinding,
  sbpRegisterPaymentWithBinding,
  setSbpPaymentRegisterOrderByContractName,
} from '~/api/api';

/** константы */
import { desktop940 } from '~/components/Grid/constants';
import {
  CARD_TEXT,
  CHECK_URL_AMOUNT,
  DEFAULT_AMOUNT,
  DEFAULT_PAYMENT,
  ERROR_MESSAGES,
  PAYMENT_OPTION_TABS,
  PAYMENT_TYPE_INTERFACE,
  SBP_TEXT,
  NO_PROMOTIONS,
  CHECK_PAYMENT_SLUG,
  CONTRACT_NOT_FOUND_TYPE,
} from './constants';
import { NEW_ACCOUNT } from '../PaymentResult/constants';
import { PAYMENT_SLUG } from '~/constants/common';
import { CASHBACK_CONFIG } from '~/components/Blocks/Templates/Payment/Promo/config';
import { OPEN_PAYMENT_SP } from '~/components/AuthWizard/constants';

/** утилиты */
import { HooksTyping } from '~/utils/typeScriptHelpers';
import { banksListImageConverter, checkUrlAccessMobile } from './utils';
import { formatNumber } from '~/utils/utils';

/** компоненты библиотеки */
import {
  Icons,
  OptionProp,
  Snoska,
  defaultTheme,
} from 'cordis-core-ui-planeta';

/** типы */
import {
  AmountProps,
  BanksProps,
  SbpPaymentProps,
  UsePaymentProps,
  UsePaymentReturnProps,
} from './types';
import { PAYMENT_TYPE } from '~/interfaces/PromoInterface';
import { SbpInfo, SbpInfoByBinding } from '../PaymentResult/interfaces';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileSBPStore from './MobileSBP/store/useMobileSBPStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
import usePinWizardStore from '../../Shared/PinWizard/store/usePinWizardStore';
import useListOfSbpBanksStore from '../../Shared/ListOfSbpBanks/store/useListOfSbpBanksStore';

export const usePayment = ({
  paymentStyle,
  payByCreditCard,
  handleCheckIsValid,
  isRememberPaymentData,
  selectedSbpAccount,
  promoListContent,
  activePromotion,
  setActivePromotion,
  getPromoInfo,
  cardLimit,
  sbpLimit,
}: UsePaymentProps): UsePaymentReturnProps => {
  const router = useRouter();
  const {
    sbpBanksStore: { sbpBanks },
    authStore: { auth, isAuth, isTemporaryTokenAuth, setIsMenuOpen },
  } = useRootStore();
  const {
    isOpenSBPCards,
    setIsOpenSBPCards,
    fetchAmount,
    waitingPage,
    setFetchAmount,
    setPayload,
    isZeroing,
    resetStore,
  } = useMobileSBPStore();
  const {
    openSPAfterAuthorizationState,
    setOpenSPAfterAuthorizationState,
  } = useMakeAuthStore();
  const { setIsShowPinSidePage, token } = usePinWizardStore();
  const {
    isListOfSbpBanksOpen,
    setIsListOfSbpBanksOpen,
  } = useListOfSbpBanksStore();

  // Номер договора
  const [contractNumber, setContractNumber] = useState<string>(
    isAuth ? auth.contractName : '',
  );
  // Сумма
  const [amount, setAmount] = useState<AmountProps>(DEFAULT_AMOUNT);
  // Текст ошибки
  const [errorMessage, setErrorMessage] = useState<string>('');
  // Флаг загрузки
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Количество попыток проверок доступа до эквайринга
  const fetchAmountRef = useRef<number>(CHECK_URL_AMOUNT);
  // Сайдпейдж QR
  const [isSidePageOpen, setIsSidePageOpen] = useState<boolean>(false);
  // Изображение qr-кода
  const [QRcode, setQRcode] = useState<string>('');
  // id qr-кода
  const [QRId, setQRId] = useState<string>('');
  // id подписки на сбп счёт
  const [
    sbpSubscriptionInfo,
    setSbpSubscriptionInfo,
  ] = useState<SbpPaymentProps>(null);
  // Статус оплаты СБП
  const [sbpStatus, setSbpStatus] = useState<{
    contractName: string;
    qrId: string;
  }>(null);
  // id таймера для проверки платежа по СБП
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  // Выбранный способ оплаты tabs
  const [activeTabIndex, setActiveTabIndex] = useState<number>(null);
  // Выбранный способ оплаты select
  const [
    selectedPaymentMethod,
    setSelectedPaymentMethod,
  ] = useState<OptionProp>(null);
  const [convertedSbpBanksList, setConvertedSbpBanksList]: HooksTyping<
    BanksProps[]
  > = useState<BanksProps[]>(null);

  /** Сайдпейдж с промо-акцией */
  const [isOpenPromo, setIsOpenPromo]: HooksTyping<boolean> = useState<boolean>(
    false,
  );
  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  // Хранение состояние клика по кнопке оплаты (предотвращает лишний вызов хука эффекта на контекст акций, если есть несколько блоков на странице)
  // todo: после появления ещё какой-нибудь акции пристально посмотреть на структуру контекста
  const clicked = useRef<boolean>(false);

  const switchToPayment = () => {
    if (router.query?.contractNameFromShortLink)
      router.push(`/${PAYMENT_SLUG}`, `/${PAYMENT_SLUG}`, {
        shallow: true,
      });
  };

  const redirectToCheckPayment = (isCorrect?: boolean) => {
    /** Оплата с привязанного счёта сбп */
    const linkedAccount =
      selectedSbpAccount && selectedSbpAccount.label !== NEW_ACCOUNT
        ? selectedSbpAccount.value
        : null;

    const sbpParams = `${
      sbpStatus
        ? `&contractNumber=${sbpStatus.contractName}&qrId=${sbpStatus.qrId}&isRememberPaymentData=${isRememberPaymentData}&sbpSubscriptionId=${sbpSubscriptionInfo?.sbpSubscriptionId}&linkedAccount=${linkedAccount}&amount=${amount.forSend}`
        : ''
    }`;
    router.replace(
      {
        query: {},
      },
      undefined,
      {
        shallow: true,
      },
    );
    router.push(
      `/${CHECK_PAYMENT_SLUG}?isCorrect=${isCorrect}${sbpParams}`,
      `/${CHECK_PAYMENT_SLUG}?isCorrect=${isCorrect}${sbpParams}`,
    );
  };

  /** Действие по доступности адреса оплаты */
  const onUrlAccess = () => {
    switchToPayment();
    setIsOpenSBPCards(true);
    setIsLoading(false);
  };

  /** Произвести оплату после авторизации */
  const isPaymentAfterAuthorization = useMemo(
    () => openSPAfterAuthorizationState === OPEN_PAYMENT_SP && isAuth,
    [openSPAfterAuthorizationState, isAuth],
  );

  useEffect(() => {
    if (
      (token || isPaymentAfterAuthorization) &&
      isSBP &&
      +amount.forSend &&
      contractNumber
    )
      paymentViaSBP();
  }, [token, isPaymentAfterAuthorization]);

  // Оплата через СБП
  const paymentViaSBP = async (
    contractNameFromShortLink?: string,
    newAmount?: number,
  ) => {
    const tokenFromStorage = window.sessionStorage.getItem(contractNumber);
    /** открыть сп пин-кода */
    if (
      !token &&
      !tokenFromStorage &&
      isRememberPaymentData &&
      !isAuth &&
      setIsShowPinSidePage
    ) {
      setIsShowPinSidePage(true);
      return;
    }
    setFetchAmount(CHECK_URL_AMOUNT);
    try {
      const res =
        selectedSbpAccount && selectedSbpAccount.label !== NEW_ACCOUNT
          ? await sbpRegisterOrderByBinding(
              newAmount ?? +amount.forSend,
              selectedSbpAccount.value,
            )
          : isRememberPaymentData
          ? await sbpRegisterPaymentWithBinding(
              newAmount ?? +amount.forSend,
              token || tokenFromStorage,
            )
          : contractNameFromShortLink
          ? await getStaticSbpPaymentByContractName(contractNameFromShortLink)
          : await setSbpPaymentRegisterOrderByContractName(
              contractNumber,
              newAmount ?? +amount.forSend,
            );

      if ((res as SbpInfoByBinding)?.paymentStatus === 'InProgress') {
        setQRId(res.qrId);
      }
      if (isMobile && (res as SbpInfo).payload) {
        setQRId(res.qrId);
        setPayload((res as SbpInfo).payload);
        await checkUrlAccessMobile(
          (res as SbpInfo).payload,
          fetchAmount,
          setFetchAmount,
          redirectToCheckPayment,
          onUrlAccess,
        );
      }
      if (!isMobile && (res as SbpInfo).qrImage) {
        setQRcode((res as SbpInfo).qrImage);
        setQRId(res.qrId);
        setIsSidePageOpen(true);
        setIsLoading(false);
      }
      if (isRememberPaymentData) {
        setSbpSubscriptionInfo(res as SbpInfo);
      }
      setActivePromotion(null);
      setIsMenuOpen(false);
    } catch (e) {
      const err = e.errorMessage ? JSON.parse(e.errorMessage) : {};
      if (err.Type === CONTRACT_NOT_FOUND_TYPE) {
        setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
        return;
      }
      setErrorMessage(ERROR_MESSAGES.ERROR);
    } finally {
      setOpenSPAfterAuthorizationState(null);
    }
  };

  // Возвращает информацию о состоянии платежа с использованием СБП
  const sbpPaymentCheckOrderState = async (
    contractNumberProp: string,
    currentAccount?: number,
  ) => {
    try {
      // Порешаем проблемы с привязанным СБП на странице проверке платежа
      if (currentAccount && currentAccount !== 1) {
        setSbpStatus({ contractName: contractNumberProp, qrId: QRId });
        clearInterval(timerId);
        return;
      }
      const res = await getSbpPaymentCheckOrderState(contractNumberProp, QRId);
      if (res.statusCode === 'Success')
        setSbpStatus({ contractName: contractNumberProp, qrId: QRId });
    } catch (error) {
      const err = JSON.parse(error?.errorMessage);
      if (err.Type === CONTRACT_NOT_FOUND_TYPE) {
        clearInterval(timerId);
        setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
        return;
      }
      clearInterval(timerId);
      setIsSidePageOpen(false);
      setErrorMessage(ERROR_MESSAGES.ERROR);
    }
  };

  // Выбран метод оплаты СБП
  const isSBP = !!(
    selectedPaymentMethod?.value === '1' ||
    activeTabIndex === PAYMENT_OPTION_TABS.SBP
  );

  // Флаг работы СБП // Change when sbp is gonna available
  const isAvailableSBP = true;

  // Проверка платежа СБП каждые 3 секунды
  useEffect(() => {
    if (
      QRId &&
      !sbpStatus &&
      (waitingPage ||
        !isMobile ||
        (isMobile &&
          selectedSbpAccount &&
          selectedSbpAccount.label !== NEW_ACCOUNT))
    ) {
      (async () => {
        const id = setInterval(
          () =>
            sbpPaymentCheckOrderState(
              contractNumber,
              selectedSbpAccount?.value,
            ),
          3000,
        );
        setTimerId((id as unknown) as NodeJS.Timeout);
      })();
    }
    if (!waitingPage && isMobile && timerId) clearInterval(timerId);
    if (sbpStatus) {
      clearInterval(timerId);
      setIsSidePageOpen(false);
      redirectToCheckPayment(true);
    }
  }, [QRId, sbpStatus, waitingPage]);

  // tabs с иконками
  const tabsIcons = [
    {
      icon: <Icons.WhiteCardIcon />,
      inactiveIcon: <Icons.SmallCardIcon />,
    },
    {
      icon: <Icons.ColorSbpIcon />,
    },
  ];

  // Способы оплаты select
  const paymentMethodData = [
    {
      label: CARD_TEXT.TOP_UP_ACCOUNT_WITH_A_BANK_CARD,
      value: '0',
      leftIcon: <Icons.SmallCardIcon />,
    },
    {
      label: SBP_TEXT.TOP_UP_ACCOUNT_VIA_SPB,
      value: '1',
      leftIcon: <Icons.ColorSbpIcon />,
    },
  ];

  /** Вкладка оплаты картой */
  const isCardTab =
    selectedPaymentMethod === paymentMethodData[0] ||
    activeTabIndex === PAYMENT_OPTION_TABS.CARD;
  /** Вкладка оплаты сбп */
  const isSbpTab =
    selectedPaymentMethod === paymentMethodData[1] ||
    activeTabIndex === PAYMENT_OPTION_TABS.SBP;

  // При вводе суммы
  const handleOnChangeInputMoney = (params) => {
    const { value, forSend } = params;
    setAmount(
      isCardTab && cardLimit.maxAmount && forSend > cardLimit.maxAmount
        ? {
            value: `${formatNumber(cardLimit.maxAmount)} \u20BD`,
            forSend: `${cardLimit.maxAmount}`,
          }
        : isSbpTab && sbpLimit.maxAmount && forSend > sbpLimit.maxAmount
        ? {
            value: `${formatNumber(sbpLimit.maxAmount)} \u20BD`,
            forSend: `${sbpLimit.maxAmount}`,
          }
        : { value, forSend },
    );
  };

  // Сноска
  const snoska = () => {
    if (isSBP) {
      return isDesktop940 ? (
        <Snoska
          color={defaultTheme.colors.gray}
          className="payment-form__button-wrapper__snoska"
        >
          Отсканируйте сгенерированный QR-код камерой телефона.{' '}
          <Snoska
            className="payment-form__button-wrapper__snoska__link"
            color={defaultTheme.colors.planeta}
            onClick={() => setIsListOfSbpBanksOpen(true)}
          >
            Список банков
          </Snoska>{' '}
          поддерживающих подписки СБП.
        </Snoska>
      ) : (
        <Snoska
          color={defaultTheme.colors.gray}
          className="payment-form__button-wrapper__snoska"
        >
          Выберите банк в{'\u00A0'}открывшемся окне и{'\u00A0'}завершите платёж
          в{'\u00A0'}приложении банка.
        </Snoska>
      );
    }
    return (
      <Snoska
        color={defaultTheme.colors.gray}
        className="payment-form__button-wrapper__snoska"
      >
        Вы{'\u00A0'}будете перенаправлены на{'\u00A0'}защищённый сайт банка.
      </Snoska>
    );
  };

  /** Список банков СБП */
  const getSbpBanks = async () => {
    const res = await banksListImageConverter(sbpBanks);
    setConvertedSbpBanksList(res);
  };

  /** Сбрасываем состояния, при закрытии СП MobileSBP */
  useEffect(() => {
    if (isZeroing) {
      setQRId('');
      setSbpStatus(null);
      setActivePromotion(null);
      resetStore();
    }
  }, [isZeroing]);

  const onCloseClickSbpDesktop = () => {
    clearInterval(timerId);
    setQRId('');
    setQRcode('');
    setSbpStatus(null);
    setIsSidePageOpen(false);
    setActivePromotion(null);
    setSbpSubscriptionInfo(null);
  };

  // Значение select по умолчанию
  useEffect(() => {
    if (paymentStyle?.type === PAYMENT_TYPE_INTERFACE.SELECT) {
      setSelectedPaymentMethod(
        paymentStyle?.defaultPayment === DEFAULT_PAYMENT.SBP
          ? paymentMethodData[1]
          : paymentMethodData[0],
      );
    } else if (paymentStyle?.type === PAYMENT_TYPE_INTERFACE.TABS) {
      setActiveTabIndex(
        paymentStyle?.defaultPayment === DEFAULT_PAYMENT.SBP
          ? PAYMENT_OPTION_TABS.SBP
          : PAYMENT_OPTION_TABS.CARD,
      );
    }

    return () => {
      if (timerId) clearInterval(timerId);
      clicked.current = false;
    };
  }, []);

  useEffect(() => {
    if (!isListOfSbpBanksOpen && !isAuth && !isOpenSBPCards) return;
    getSbpBanks();
  }, [isListOfSbpBanksOpen, isAuth]);

  // Кнопка "Оплатить"
  const payHandler = async () => {
    setActivePromotion(null);
    clicked.current = true;
    setIsLoading(true);

    if (!handleCheckIsValid()) {
      setIsLoading(false);
      return;
    }

    try {
      const pinToken = window.sessionStorage.getItem(contractNumber);
      if (pinToken) {
        const res = await checkAuth(pinToken);
        if (res.status !== 200) {
          window.sessionStorage.removeItem(contractNumber);
        }
      }
      /** Получает информацию о промо-акциях */
      await getPromoInfo(
        contractNumber,
        +amount.forSend,
        isSBP ? PAYMENT_TYPE.SBP : PAYMENT_TYPE.CARD_PAYMENT,
      );
    } catch (error) {
      const err = error.errorMessage ? JSON.parse(error.errorMessage) : {};
      if (err.Type === CONTRACT_NOT_FOUND_TYPE) {
        setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
      }
      setErrorMessage(ERROR_MESSAGES.ERROR);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!clicked.current) return;
    if (activePromotion) clicked.current = false;

    const exceededCashback =
      promoListContent[activePromotion]?.isCashbackBottoms &&
      +amount.forSend >= CASHBACK_CONFIG.max.amount;

    if (activePromotion === NO_PROMOTIONS || exceededCashback) {
      if (isTemporaryTokenAuth) {
        setErrorMessage(ERROR_MESSAGES.NOT_AVAILABLE_TO_EMPLOYEE);
        return;
      }
      if (isSBP) {
        paymentViaSBP();
        return;
      }

      payByCreditCard();
      return;
    }

    if (activePromotion) {
      setIsOpenPromo(true);
      setIsLoading(false);
    }
  }, [activePromotion]);

  return {
    contractNumber,
    setContractNumber,
    amount,
    errorMessage,
    setErrorMessage,
    isLoading,
    setIsLoading,
    fetchAmountRef,
    isSidePageOpen,
    setIsSidePageOpen,
    QRcode,
    setQRcode,
    QRId,
    setQRId,
    sbpStatus,
    setSbpStatus,
    timerId,
    setTimerId,
    activeTabIndex,
    setActiveTabIndex,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    paymentViaSBP,
    isSBP,
    isAvailableSBP,
    handleOnChangeInputMoney,
    snoska,
    tabsIcons,
    paymentMethodData,
    convertedSbpBanksList,
    onCloseClickSbpDesktop,
    isOpenPromo,
    setIsOpenPromo,
    payHandler,
    isCardTab,
    isSbpTab,
  };
};
