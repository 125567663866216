/** libraries */
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
import {
  Button,
  ButtonStyleTypes,
  defaultTheme,
  H2,
  H3,
  Icon,
  Icons,
  LeadingText,
  PriceTag,
  PriceTagBackgroundColor,
  Text,
} from 'cordis-core-ui-planeta';
/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import { formatNumber, pluralizeAll } from '~/utils/utils';
import LinkWrapper from '~/components/LinkWrapper';
/** constants */
import { desktop1100, desktop940 } from '~/components/Grid/constants';
import { DEFAULT_ERROR } from '~/components/Blocks/Templates/ProductSwitcher/ProductSwitcherWizard/constants';
import { CHATLE } from '../../Templates/Pab2c/Contract/constants';
/** styles */
import { StyledAnnualProductRenewalWizard } from './styles';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useAnnualProductRenewalStore from './store/useAnnualProductRenewalStore';

const AnnualProductRenewalWizard: FC = () => {
  const {
    pab2cBalanceStore: { chatleBudgetSaldo },
    authStore: { auth },
    prolongationStore: { prolongationInfo, agreement },
  } = useRootStore();
  const { seriesName, result } = useAnnualProductRenewalStore();

  /** Разница между балансом и суммой без чатлов */
  const amountWithoutChatles =
    auth.balance - prolongationInfo?.prolongation?.price;

  /** Разница между балансом и суммой с chatle */
  const differenceBetweenBalanceAndAmount =
    amountWithoutChatles + chatleBudgetSaldo;

  /** На балансе достаточно денег для продления годового продукта */
  const isAmountToRenew = differenceBetweenBalanceAndAmount >= 0;

  /** Используются чатлы */
  const isUseChatles = isAmountToRenew && amountWithoutChatles < 0;

  /** Количество используемых чатлов */
  const chatles = isUseChatles ? Math.abs(amountWithoutChatles) : 0;

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  return (
    <StyledAnnualProductRenewalWizard>
      {result.isResult && result.isCorrect && (
        <>
          <div className="result-icon">
            <Icon icon={<Icons.SuccessIconAnimated />} />
          </div>
          {isDesktop1100 ? <H2>{result.text}</H2> : <H3>{result.text}</H3>}
        </>
      )}

      {result.isResult && !result.isCorrect && (
        <>
          <div className="result-icon">
            <Icon icon={<Icons.NotOkBigIcon />} />
          </div>
          {isDesktop1100 ? <H2>{DEFAULT_ERROR}</H2> : <H3>{DEFAULT_ERROR}</H3>}
        </>
      )}

      {!result.isResult && (
        <>
          <div className="renewal__price-tag">
            <PriceTag
              header="Стоимость продления"
              value={`${formatNumber(prolongationInfo?.prolongation?.price)} ₽`}
              backgroundColor={
                isAmountToRenew
                  ? PriceTagBackgroundColor.OK
                  : PriceTagBackgroundColor.WARNING
              }
              fontColor={
                isAmountToRenew
                  ? defaultTheme.colors.black
                  : defaultTheme.colors.warning
              }
              subscription={
                // eslint-disable-next-line no-nested-ternary
                isUseChatles
                  ? `Из них ${pluralizeAll(chatles, CHATLE, null, null, true)}`
                  : isAmountToRenew
                  ? `На вашем счету ${formatNumber(auth.balance)} ₽`
                  : `Не хватает ${formatNumber(
                      Math.abs(differenceBetweenBalanceAndAmount),
                    )} ₽`
              }
              width="auto"
              headerType={isDesktop940 ? 'H2' : 'H3'}
            />
            {!isAmountToRenew && (
              <LinkWrapper href="/payment">
                <Button styleType={ButtonStyleTypes.SECONDARY}>
                  {isDesktop940 ? 'Пополнить баланс' : 'Пополнить'}
                </Button>
              </LinkWrapper>
            )}
          </div>
          <div className="renewal__term">
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              К оставшемуся сроку будет добавлено
            </Text>
            <LeadingText color={defaultTheme.colors.black}>
              {prolongationInfo?.prolongation?.promoDaysInSeries +
                (prolongationInfo?.tariffDurationChangePromoInfo
                  ?.additionalDays ?? 0)}{' '}
              календарных дней
            </LeadingText>
          </div>
          <div className="renewal__warning-block">
            <Text lineHeight="24px">
              Срок действия Продукта увеличится с момента завершения
              автоматической обработки данной заявки.
            </Text>
          </div>
          <div>
            <Text lineHeight="24px" fontWeightBold>
              Согласие на продление продукта «{seriesName}»
            </Text>
            <Text lineHeight="24px">{parseHtml(agreement)}</Text>
          </div>
        </>
      )}
    </StyledAnnualProductRenewalWizard>
  );
};

export default observer(AnnualProductRenewalWizard);
