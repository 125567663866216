/** библиотеки */
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** компоненты библиотеки */
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
/** константы */
import { desktop940 } from '~/components/Grid/constants';
import { ACTION_MAP, PRODUCT_CHANGE_ERRORS } from '../constants';
import { ConnectionMethod } from '~/constants/common';
/** интерфейсы */
import { ProductSwitcherWizardFooterProps } from './types';
import { TariffProps } from '../interfaces';
/** стили */
import { StyledFooter } from './style';

/** stores */
import { useRootStore } from '~/stores/RootStore';
/** stores */
import useContactsAndNotificationsStore from '~/components/Blocks/Shared/ContactsAndNotifications/store/useContactsAndNotificationsStore';

/**
 * Блок ProductSwitcherFooter. SidePageFooter подключения/смены продукта.
 */
const ProductSwitcherWizardFooter: FC<ProductSwitcherWizardFooterProps> = ({
  tariff,
  tariffConnect,
  alreadyConnected,
  agreement,
  isLoading,
  productChangeError,
}: ProductSwitcherWizardFooterProps) => {
  const {
    allowedTariffStore: { allowedTariff },
    authStore: { auth, isTemporaryTokenAuth },
  } = useRootStore();
  const {
    haveVerifiedPhone,
    addingContactStore: { setIsShowAddingContact },
  } = useContactsAndNotificationsStore();
  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Баланс больше чем цена оборудования */
  const migrationPrice =
    ((allowedTariff as unknown) as TariffProps[]).find(
      (item) => item.tariffTo.seriesCode === tariff?.seriesCode,
    )?.price ?? 0;
  const isBalanceMoreThanPrice = auth.balance - migrationPrice >= 0;

  /** Текст для кнопки */
  const buttonText = () => {
    if (alreadyConnected)
      return isDesktop940
        ? ACTION_MAP.Change.desktop
        : ACTION_MAP.Change.mobile;

    return isDesktop940
      ? ACTION_MAP.Connect.desktop
      : ACTION_MAP.Connect.mobile;
  };

  const isNotEnoughMoney =
    productChangeError === PRODUCT_CHANGE_ERRORS.NOT_ENOUGH_MONEY;

  if (productChangeError && !isNotEnoughMoney) return <></>;

  const buttonHandler = async () => {
    /** Если нет телефонов на договоре - надо добавить */
    const havePhone = await haveVerifiedPhone();
    if (!havePhone) {
      setIsShowAddingContact(true, tariffConnect);
      return;
    }
    tariffConnect();
  };

  return (
    <StyledFooter>
      <Button
        className="product-change__buy-button"
        disabled={
          (tariff?.connectionMethod === ConnectionMethod.FTTH &&
            !isBalanceMoreThanPrice) ||
          !agreement ||
          !!productChangeError ||
          isTemporaryTokenAuth
        }
        onClick={buttonHandler}
        loading={isLoading}
      >
        {buttonText()}
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(ProductSwitcherWizardFooter);
