/** библиотеки */
import React, { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { observer } from 'mobx-react';

/** компоненты библиотеки */
import {
  defaultTheme,
  H3,
  Icon,
  Icons,
  LeadingText,
  Link,
  Loader,
  SidePage,
  Text,
} from 'cordis-core-ui-planeta';
import DevicePriceTabs from '~/components/Blocks/Templates/Pab2c/Devices/Components/DevicePriceTabs';
import LinkWrapper from '~/components/LinkWrapper';
import WarrantyPlusWizardFooter from '~/components/Blocks/Templates/Pab2c/Devices/Components/WarrantyPlusWizard/WarrantyPlusWizardFooter';
import WarrantyPlusWizard from '~/components/Blocks/Templates/Pab2c/Devices/Components/WarrantyPlusWizard/WarrantyPlusWizard';
import DeviceReturnWizard from '~/components/Blocks/Templates/Pab2c/Devices/Components/DeviceReturnWizard/DeviceReturnWizard';

/** стили */
import {
  DeviceCardWizardStyled,
  StyledCardWizardImage,
  StyledCardWizardImageWrapper,
  StyledIconLink,
  StyledWarrantyTag,
} from './style';
import { StyledSidePage } from '../../Pab2cStyle';

/** константы */
import {
  DAYS,
  DEFAULT_IMAGE,
  IMAGE_HEIGHT_DESKTOP,
  IMAGE_HEIGHT_MOBILE,
} from './constants';
import { desktop500 } from '~/components/Grid/constants';
import {
  DEVICE_TYPES_GENITIVE,
  OWNERSHIP_CODES,
  OWNERSHIP_MAP_EXTENDED,
} from '~/components/Blocks/Templates/Pab2c/Devices/constants';
import { WARRANTY_WIZARD_STATES } from '~/components/Blocks/Templates/Pab2c/Devices/Components/WarrantyPlusWizard/constants';
import { DEFAULT_IMAGE_SIZE } from '~/components/Blocks/Shared/DevicesCard/constants';
import { SIDE_PAGES, STATE_SP } from '~/components/AuthWizard/constants';

/** типы */
import { DeviceCardWizardProps } from './types';
import { ManualsProps } from '../../../../Devices/interfaces';

/** утилиты */
import { formatNumber, isExternal, pluralizeAll } from '~/utils/utils';
import Portal from '~/components/Portal/Portal';

/** api */
import { getRegressDeviceAgreement } from '~/api/apiPab2c';

/** stores */
import { useDevicesStore } from '../../stores/useDevicesStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
import useDeviceAfterAuthStore from '../../stores/useDeviceAfterAuthStore';
import { useRootStore } from '~/stores/RootStore';

/**
 * Блок pab2cDevices. Компонент сайдпейджа передачи оборудования в собственность
 */
const DeviceCardWizard: FC<DeviceCardWizardProps> = ({
  warrantyState,
}: DeviceCardWizardProps) => {
  const {
    authStore: { isAuth },
  } = useRootStore();

  const {
    detailedDeviceStore: { detailedDevice: device, deviceOwnership },
    devicePurchasesStore: { openSidePage, setIsDevicePurchasesWizardShow },
    devicePurchase,
    setIsWarrantyPlusAgreement,
  } = useDevicesStore();

  const {
    openSPAfterAuthorizationState,
    setOpenSPAfterAuthorizationState,
  } = useMakeAuthStore();
  const deviceAfterAuthStore = useDeviceAfterAuthStore();

  // Флаг отображения детального сайдпейджа приобретения оборудования
  const [isReturnDeviceShow, setIsReturnDeviceShow] = useState<boolean>(false);

  const {
    isWarrantyPlusWizardAgree,
    setIsWarrantyPlusWizardAgree,
    isWarrantyPlusWizardShow,
    setIsWarrantyPlusWizardShow,
    isWarrantyPlusWizardLoading,
    warrantyWizardState,
    setWarrantyWizardState,
    warrantyPlusResult,
    setWarrantyPlusResult,
    toggleWarranty,
    handleWarrantyAgreementState,
  } = warrantyState;

  const isMobile = useMediaQuery({
    query: `(min-width: ${desktop500}px)`,
  });

  const imgMinHeight = isMobile ? IMAGE_HEIGHT_DESKTOP : IMAGE_HEIGHT_MOBILE;

  // Ссылка на изображение
  const imageSrc = isExternal(device.images)
    ? device.images
    : `${process.env.STATIC_SERVER}${device.images || DEFAULT_IMAGE}`;

  const getFormOwning = (): string => {
    const ownershipKey = Object.keys(OWNERSHIP_MAP_EXTENDED).find(
      (ownerKey) => ownerKey === device.ownership,
    );

    if (!ownershipKey) return null;
    return OWNERSHIP_MAP_EXTENDED[ownershipKey];
  };

  /** Загрузка соглашения возврата оборудования */
  const [
    isLoadingReturnAgreement,
    setIsLoadingReturnAgreement,
  ] = useState<boolean>(false);

  /** Соглашение о возврате оборудования */
  const [returnDeviceAgreement, setReturnDeviceAgreement] = useState<string>(
    '',
  );

  const returnDevice = async () => {
    setIsLoadingReturnAgreement(true);
    try {
      const res = await getRegressDeviceAgreement(device.deviceTypeCode);
      setReturnDeviceAgreement(res);
      setIsReturnDeviceShow(true);
      setIsLoadingReturnAgreement(false);
    } catch (e) {
      if (e.statusCode === 401) {
        deviceAfterAuthStore.setDeviceId(device.id);
        setOpenSPAfterAuthorizationState({
          [SIDE_PAGES.REGRESS_DEVICE]: STATE_SP.AGREEMENT,
        });
      }
      console.error('getRegressDeviceAgreement', e);
      setIsLoadingReturnAgreement(false);
    }
  };

  const showSerial = () => {
    return (
      <>
        <div className="device-card-wizard__block-inner">
          <Text color={defaultTheme.colors.shadow} lineHeight="24px">
            Серийный&nbsp;номер
          </Text>
          <div className="device-card-wizard__block-value">
            <LeadingText color={defaultTheme.colors.black}>
              {device?.serial}
            </LeadingText>
          </div>
        </div>
      </>
    );
  };

  const showWarrantyPlus = () => {
    if (!device.warrantyPlusInfo?.isOrdered) return null;
    return (
      <StyledWarrantyTag
        colorTag={defaultTheme.colors.green}
        color={defaultTheme.colors.green}
      >
        ГАРАНТИЯ&nbsp;&#43;
      </StyledWarrantyTag>
    );
  };

  const showDocuments = (manual: ManualsProps): JSX.Element => {
    const link = isExternal(manual.link)
      ? manual.link
      : `${process.env.STATIC_SERVER}${manual.link}`;

    return (
      <StyledIconLink key={manual.link}>
        <LinkWrapper href={link} target="_blank">
          <Icon icon={<Icons.DocumentIcon />} />
          <span>{manual.name}</span>
        </LinkWrapper>
      </StyledIconLink>
    );
  };

  const showWarranty = () => {
    return (
      <div className="device-card-wizard__block-inner">
        <Text color={defaultTheme.colors.shadow} lineHeight="24px">
          Гарантийный&nbsp;срок
        </Text>
        <div className="device-card-wizard__block-value">
          <LeadingText color={defaultTheme.colors.black}>
            До{' '}
            {format(parseISO(device?.warrantyEndDate), 'd MMMM Y', {
              locale: ru,
            })}
          </LeadingText>
        </div>
      </div>
    );
  };

  const showWarrantyLink = (): JSX.Element => {
    // Рассрочка без подключённой гарантии
    const isBoughtLeasingWithoutWarranty =
      device?.ownership === OWNERSHIP_CODES.BOUGHT_LEASING &&
      !device?.warrantyPlusInfo?.isOrdered;
    // Оборудование в собственности и гарантия подключена
    const isBoughtWithWarranty =
      device.ownership === OWNERSHIP_CODES.BOUGHT &&
      !device.warrantyPlusInfo?.isOrdered;
    // Оборудование доступно в перевод в собственность и нет подключённой гарантии
    const canBeingOwnerWithoutWarranty =
      [
        OWNERSHIP_CODES.LEASING_WITH_OWNERSHIP,
        OWNERSHIP_CODES.LEASING,
      ].includes(device.ownership) &&
      device.ownershipEndDays <= 0 &&
      !device.warrantyPlusInfo?.isOrdered;

    if (
      isBoughtLeasingWithoutWarranty ||
      isBoughtWithWarranty ||
      // Нет цен
      device.warrantyPlusInfo.price === null ||
      canBeingOwnerWithoutWarranty
    )
      return null;

    const warrantyLinkHandler = () => {
      if (isWarrantyPlusWizardLoading) return;
      setWarrantyWizardState(
        device.warrantyPlusInfo.isOrdered
          ? WARRANTY_WIZARD_STATES.OFF
          : WARRANTY_WIZARD_STATES.ON,
      );
      setIsWarrantyPlusWizardShow(true);
    };

    return (
      <StyledIconLink>
        <Link onClick={warrantyLinkHandler}>
          {isWarrantyPlusWizardLoading && <Loader small />}
          {!isWarrantyPlusWizardLoading && (
            <Icon
              icon={
                device.warrantyPlusInfo?.isOrdered ? (
                  <Icons.CircleCancelIcon />
                ) : (
                  <Icons.CirclePlusIcon />
                )
              }
            />
          )}
          <span>
            {' '}
            {device.warrantyPlusInfo?.isOrdered
              ? 'Отключить'
              : 'Подключить'}{' '}
            Гарантия +
          </span>
        </Link>
      </StyledIconLink>
    );
  };

  const showOwnerLink = (): JSX.Element => {
    if (
      ![
        OWNERSHIP_CODES.LEASING_WITH_OWNERSHIP,
        OWNERSHIP_CODES.LEASING,
      ].includes(device.ownership) ||
      device.ownershipEndDays > 0
    )
      return null;

    return (
      <StyledIconLink>
        <Link onClick={() => openSidePage(deviceOwnership, false)}>
          <Icon className="header__close" icon={<Icons.CircleOkIcon />} />
          <span>Получить в собственность</span>
        </Link>
      </StyledIconLink>
    );
  };

  const showReturnLink = (): JSX.Element => {
    if (device.ownership === OWNERSHIP_CODES.BOUGHT || device.priceOn === null)
      return null;
    return (
      <StyledIconLink>
        <Link onClick={returnDevice}>
          {isLoadingReturnAgreement && <Loader small />}
          {!isLoadingReturnAgreement && (
            <Icon className="header__close" icon={<Icons.CircleMinusIcon />} />
          )}
          <span>Вернуть оборудование</span>
        </Link>
      </StyledIconLink>
    );
  };

  const showPrice = () => {
    const warrantyPrice = !device.warrantyPlusInfo?.isOrdered
      ? 0
      : device.warrantyPlusInfo?.price ?? 0;

    const priceOn = device.priceOn + warrantyPrice;
    const priceOff = device.priceOff + warrantyPrice;

    return (
      <>
        {device.ownership !== OWNERSHIP_CODES.BOUGHT && (
          <div className="device-card-wizard__price-block device-card-wizard__block">
            {device.ownership !== OWNERSHIP_CODES.BOUGHT_LEASING && (
              <div className="device-card-wizard__block-inner">
                {priceOn > 0 && (
                  <DevicePriceTabs cost={device.priceOn + warrantyPrice} />
                )}
                {(priceOn === 0 || priceOn === null) && (
                  <H3 className="devices__item-price">
                    {device.priceOn ?? 0} &#8381;
                  </H3>
                )}
                <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                  {priceOff ?? 0} ₽ в день
                  <br />в случае приостановки
                </Text>
              </div>
            )}

            {!!device.annuity &&
              device.ownership === OWNERSHIP_CODES.BOUGHT_LEASING && (
                <div className="device-card-wizard__block-inner">
                  <DevicePriceTabs cost={device.annuity + warrantyPrice} />
                </div>
              )}

            {device.leaseDays && (
              <div className="device-card-wizard__block-inner">
                <Text color={defaultTheme.colors.shadow} lineHeight="24px">
                  Осталось
                </Text>
                <div className="device-card-wizard__block-value">
                  <LeadingText color={defaultTheme.colors.black}>
                    <strong>{pluralizeAll(device.leaseDays, DAYS)}</strong>
                  </LeadingText>
                </div>
                {device.leaseSaldo > 0 && (
                  <Text color={defaultTheme.colors.shadow}>
                    Оплатить {formatNumber(device?.leaseSaldo)} ₽
                  </Text>
                )}
              </div>
            )}

            {device.ownership !== OWNERSHIP_CODES.BOUGHT_LEASING && (
              <div className="device-card-wizard__block-inner">
                <Text color={defaultTheme.colors.shadow} lineHeight="24px">
                  {device?.ownershipEndDays > 0 ? (
                    <>
                      Перевод&nbsp;в&nbsp;собственность&nbsp;будет
                      <br />
                      доступен через
                    </>
                  ) : (
                    <>Перевод&nbsp;в&nbsp;собственность</>
                  )}
                </Text>
                <div className="device-card-wizard__block-value">
                  <LeadingText color={defaultTheme.colors.black}>
                    <strong>
                      {device?.ownershipEndDays > 0
                        ? `${device?.ownershipEndDays} дней`
                        : 'Доступен'}
                    </strong>
                  </LeadingText>
                </div>
                {device?.purchasePrice > 0 && device?.ownershipEndDays > 0 && (
                  <Text color={defaultTheme.colors.shadow}>
                    Можно купить за {formatNumber(device?.purchasePrice)} ₽
                  </Text>
                )}
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  /** Возобновляем действия после повторной авторизации */
  useEffect(() => {
    if (!isAuth) return;
    if (
      openSPAfterAuthorizationState?.[SIDE_PAGES.DEVICES] === STATE_SP.AGREEMENT
    ) {
      setOpenSPAfterAuthorizationState(null);
      setIsDevicePurchasesWizardShow(true);
    }
    if (
      openSPAfterAuthorizationState?.[SIDE_PAGES.DEVICES] === STATE_SP.ACTION
    ) {
      const {
        resetDeviceAfterAuthStore,
        devicePurchaseState: { isWarrantyBind, isLease },
      } = deviceAfterAuthStore;
      resetDeviceAfterAuthStore();
      setOpenSPAfterAuthorizationState(null);

      setIsWarrantyPlusAgreement(isWarrantyBind);
      setIsDevicePurchasesWizardShow(true);
      devicePurchase(
        setOpenSPAfterAuthorizationState,
        deviceAfterAuthStore,
        isLease,
      );
    }
    if (
      openSPAfterAuthorizationState?.[SIDE_PAGES.WARRANTY] ===
      STATE_SP.AGREEMENT
    ) {
      setOpenSPAfterAuthorizationState(null);
      setIsWarrantyPlusWizardShow(true);
    }
    if (
      openSPAfterAuthorizationState?.[SIDE_PAGES.WARRANTY] === STATE_SP.ACTION
    ) {
      setOpenSPAfterAuthorizationState(null);
      setIsWarrantyPlusWizardShow(true);
      toggleWarranty();
    }
    if (
      openSPAfterAuthorizationState?.[SIDE_PAGES.REGRESS_DEVICE] ===
      STATE_SP.AGREEMENT
    ) {
      setOpenSPAfterAuthorizationState(null);
      returnDevice();
    }
  }, [isAuth]);

  const getWarrantyTitle = (short = false): string => {
    let title = `${
      device.warrantyPlusInfo?.isOrdered ? 'Отключить' : 'Подключить'
    } Гарантия+ `;

    if (!short) {
      title += `на ${DEVICE_TYPES_GENITIVE[
        device?.deviceTypeCode
      ]?.toLowerCase()}  ${device.modelName}`;
    }

    return title;
  };

  return (
    <DeviceCardWizardStyled>
      {showWarrantyPlus()}
      <StyledCardWizardImageWrapper minHeight={imgMinHeight}>
        <StyledCardWizardImage
          imgSrc={imageSrc}
          imgSizes={{
            newHeight:
              device?.imageSizeInWizard?.height ?? DEFAULT_IMAGE_SIZE.height,
            newWidth:
              device?.imageSizeInWizard?.width ?? DEFAULT_IMAGE_SIZE.width,
          }}
        />
      </StyledCardWizardImageWrapper>
      <div className="device-card-wizard">
        <div className="device-card-wizard__form-owning">{getFormOwning()}</div>
        {showPrice()}
        <div className="device-card-wizard__serial-block device-card-wizard__block">
          {showSerial()}
          {device?.warrantyEndDate !== '9999-01-01T00:00:00' && showWarranty()}
        </div>
        {device.manuals?.length > 0 &&
          device.manuals.map((manual) => showDocuments(manual))}
        {showOwnerLink()}
        {showReturnLink()}
        {showWarrantyLink()}
        {device.warrantyPlusInfo && (
          <Portal wrapperId="portalPrimary">
            <StyledSidePage
              $isHideHeader={[
                WARRANTY_WIZARD_STATES.SUCCESS_ON,
                WARRANTY_WIZARD_STATES.SUCCESS_OFF,
                WARRANTY_WIZARD_STATES.ERROR,
              ].includes(warrantyWizardState)}
              show={isWarrantyPlusWizardShow}
              headerText={getWarrantyTitle()}
              shortHeaderText={getWarrantyTitle(true)}
              onCloseClick={() => {
                setIsWarrantyPlusWizardShow(false);
                setWarrantyPlusResult(null);
                setIsWarrantyPlusWizardAgree(false);
                setWarrantyWizardState(
                  device.warrantyPlusInfo.isOrdered
                    ? WARRANTY_WIZARD_STATES.OFF
                    : WARRANTY_WIZARD_STATES.ON,
                );
              }}
              footerContainer={
                [
                  WARRANTY_WIZARD_STATES.OFF,
                  WARRANTY_WIZARD_STATES.ON,
                ].includes(warrantyWizardState) ? (
                  <WarrantyPlusWizardFooter
                    isLoading={isWarrantyPlusWizardLoading}
                    device={device}
                    isOrdered={device.warrantyPlusInfo?.isOrdered}
                    toggleWarranty={toggleWarranty}
                    isAgree={isWarrantyPlusWizardAgree}
                  />
                ) : (
                  <></>
                )
              }
              isOnlyMobileFooter
              removeScrollBar
            >
              <WarrantyPlusWizard
                device={device}
                isLoading={isWarrantyPlusWizardLoading}
                isOrdered={device.warrantyPlusInfo?.isOrdered}
                handleAgreementState={handleWarrantyAgreementState}
                viewState={warrantyWizardState}
                warrantyPlusResult={warrantyPlusResult}
                setIsWarrantyPlusWizardShow={setIsWarrantyPlusWizardShow}
              />
            </StyledSidePage>
          </Portal>
        )}

        <Portal wrapperId="portalPrimary">
          {/* Sidepage возврата оборудования */}
          <SidePage
            show={isReturnDeviceShow}
            headerText={
              device &&
              `Вернуть ${DEVICE_TYPES_GENITIVE[
                device?.deviceTypeCode
              ]?.toLowerCase()} ${device.modelName}`
            }
            removeScrollBar
            onCloseClick={() => setIsReturnDeviceShow(false)}
          >
            <DeviceReturnWizard
              device={device}
              agreement={returnDeviceAgreement}
            />
          </SidePage>
        </Portal>
      </div>
    </DeviceCardWizardStyled>
  );
};

export default observer(DeviceCardWizard);
