/* Библиотеки */
import * as React from 'react';

/* Типы */
import {
  Text,
  H3,
  Switcher,
  Tabs,
  TabsStyleTypes,
  defaultTheme,
  Icons,
} from 'cordis-core-ui-planeta';
import { CallToActionTimeShiftProps } from './CallToActionTimeShift.types';

/* Константы */
import { TABS_VARIANTS } from '../CallToAction.constants';

/* Компоненты */
import CallToActionRow from '../CallToActionRow/CallToActionRow';

/* Стили */
import {
  StyledLeftColumn,
  StyledRightColumn,
} from './CallToActionTimeShift.style';

/* Утилиты */
import { formatNumber } from '~/utils/utils';

/* Заголовок компонента "Управление просмотром" */
const TimeShiftTitle = (
  enabledTimeShift,
  setEnabledTimeShift,
  isFree,
): JSX.Element => {
  /* Меняет статус активации сервиса */
  const changeTimeShiftStatus = () => {
    setEnabledTimeShift(!enabledTimeShift);
  };

  return (
    <Switcher
      checked={enabledTimeShift}
      disabled={isFree}
      onClick={changeTimeShiftStatus}
    >
      <Text>
        Управление просмотром<sup>&nbsp;2</sup>
      </Text>
    </Switcher>
  );
};

/* Дочерний компонент "Управление просмотром", компонента CallToAction */
const CallToActionTimeShift: React.FC<CallToActionTimeShiftProps> = ({
  price,
  enabledTimeShift,
  setEnabledTimeShift,
}: CallToActionTimeShiftProps) => {
  // Флаг бесплатности сервиса
  const isFree = price === 0;
  /* Текущий индекс таба */
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
  /* Текущее значение таба */
  const [activeTabValue, setActiveTabValue] = React.useState<number>(price);
  /* Событие при изменении таба */
  const onChangeTab = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
    switch (tabIndex) {
      case 0:
        setActiveTabValue(price);
        break;
      case 1:
        setActiveTabValue(price * 30);
        break;
      default:
        setActiveTabValue(price);
    }
  };
  return (
    <CallToActionRow
      title={TimeShiftTitle(enabledTimeShift, setEnabledTimeShift, isFree)}
    >
      <StyledLeftColumn>
        {!isFree && (
          <Tabs
            value={TABS_VARIANTS}
            styleType={TabsStyleTypes.SECONDARY}
            onChange={onChangeTab}
            activeTabIndex={activeTabIndex}
          />
        )}
        <H3
          color={
            enabledTimeShift
              ? defaultTheme.colors.black
              : defaultTheme.colors.disable
          }
        >
          {!isFree
            ? `${formatNumber(activeTabValue)}\u0020\u20bd`
            : 'Бесплатно'}
        </H3>
        <div>
          <div>
            <Icons.OkIcon />
            <Text color={defaultTheme.colors.shadow}>
              Живая пауза и перемотка на любой фрагмент передачи
              <sup>&nbsp;3</sup>
            </Text>
          </div>
          <div>
            <Icons.OkIcon />
            <Text color={defaultTheme.colors.shadow}>
              Запись эфира до 7 дней
            </Text>
          </div>
        </div>
      </StyledLeftColumn>
      <StyledRightColumn>
        <div>
          <Icons.OkIcon />
          <Text color={defaultTheme.colors.shadow}>
            Живая пауза и перемотка на
            <br />
            любой фрагмент передачи<sup>&nbsp;3</sup>
          </Text>
        </div>
        <div>
          <Icons.OkIcon />
          <Text color={defaultTheme.colors.shadow}>
            Запись эфира
            <br />
            до 7 дней
          </Text>
        </div>
      </StyledRightColumn>
    </CallToActionRow>
  );
};

export default React.memo(CallToActionTimeShift);
