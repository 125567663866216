/** libraries */
import { FC, useEffect } from 'react';
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** styles */
import { StyledFooter, RecoveryText } from './styles';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { OPEN_CHANGE_PASSWORD_ACTION } from '~/components/AuthWizard/constants';
/** stores */
import useSettingsStore from '../../store/useSettingsStore';
import { useRootStore } from '~/stores/RootStore';
import useCreatePasswordStore from '~/components/Blocks/Shared/CreatePassword/store/useCreatePasswordStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

const ChangePasswordFooter: FC = () => {
  const {
    authStore: { isTemporaryTokenAuth, isAuth },
  } = useRootStore();

  const { setIsShowCreatePasswordWizard } = useCreatePasswordStore();

  const {
    changePasswordStore: {
      currentPassword,
      newPassword,
      changePassword,
      isDisableButton,
    },
  } = useSettingsStore();

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const {
    openSPAfterAuthorizationState,
    setOpenSPAfterAuthorizationState,
  } = useMakeAuthStore();

  useEffect(() => {
    if (!isAuth && !currentPassword && !newPassword) return;
    if (openSPAfterAuthorizationState === OPEN_CHANGE_PASSWORD_ACTION) {
      changePassword(setOpenSPAfterAuthorizationState);
    }
  }, [isAuth]);

  return (
    <StyledFooter>
      <Button
        onClick={() => changePassword(setOpenSPAfterAuthorizationState)}
        disabled={isDisableButton || isTemporaryTokenAuth}
      >
        Изменить
      </Button>
      {!isDesktop940 && (
        <RecoveryText
          onClick={() => {
            if (!isTemporaryTokenAuth) {
              setIsShowCreatePasswordWizard(true);
            }
          }}
          type="button"
          lineHeight="24px"
          color={
            isTemporaryTokenAuth
              ? defaultTheme.colors.disable
              : defaultTheme.colors.planeta
          }
        >
          Восстановить пароль
        </RecoveryText>
      )}
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(ChangePasswordFooter);
