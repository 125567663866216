/** libraries */
import { FC, useRef, useState, RefObject, useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  Button,
  ButtonStyleTypes,
  H3,
  defaultTheme,
  Text,
  Icon,
  Icons,
} from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import {
  StyledBanner,
  StyledBannerText,
  StyledNoSoftlines,
  StyledProductSelectorArrow,
} from './styles';
import { StyledSoftlinesCardGroup, StyledSoftlinesList } from '../Pab2cStyle';
/** components */
import SoftlineCard from '../SoftlineCard/SoftlineCard';
import LinkWrapper from '~/components/LinkWrapper';
/** interfaces */
import { SoftlineFields } from '../../../Softline/types';
import { SoftlineListProps } from './types';
/** utils */
import useWindowSize from '~/utils/useWindowSize';
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
/** constants */
import { desktop1280, desktop940 } from '~/components/Grid/constants';
import { SOFT_SLUG } from '~/constants/common';
import { SWIPE_AMOUNT } from '../constants';
import { BUSINESS_GROUP_CODE } from '../../../Summary/Summary.types';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import useSoftlineStateModelStore from '../../../Softline/stores/useSoftlineStateStore';
import { usePab2cSoftlineStore } from '../store/usePab2cSoftlineStore';
import { addAdditionalProperties } from '~/components/Blocks/Shared/Softline/utils';

const SoftlineSliderList: FC<SoftlineListProps> = ({
  softlinesRef,
}: SoftlineListProps) => {
  const {
    softlineStore: { softlineListAuthClient, isFewSubscriptions },
    summaryDataStore: { marketingGroupCode },
  } = useRootStore();
  const { onSoftlineCardClick, simIdFromBind } = useSoftlineStateModelStore();
  const {
    fieldsData,
    bannerData,
    features,
    imageSizes,
  } = usePab2cSoftlineStore();

  const softlineSubscriptions = useMemo(() => {
    if (!softlineListAuthClient.length) return [];
    return addAdditionalProperties(
      softlineListAuthClient,
      features,
      imageSizes,
      simIdFromBind,
    );
  }, [softlineListAuthClient, fieldsData]);

  // Ссылка на основной блок компонента
  const deviceListRef: RefObject<HTMLDivElement> | null = useRef(null);
  // Ссылка на основной блок компонента
  const containerRef: RefObject<HTMLDivElement> | null = useRef(null);
  const [width] = useWindowSize();
  // Минимальное расстояние, которое можно считать свайпом
  const minSwipeDistance = 6;
  // Состояние при завершения нажатия на экран
  const clientX = useRef(null);
  /** Начальные координаты клика при нажатии на карточку */
  const mouseDownCoords = (e) => {
    clientX.current = e.clientX;
  };

  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Флаг бизнес-продукта
   *  Проверяем относится ли продукт авторизованного клиента к маркетинговой группе
   */
  const isBusinessProduct = [BUSINESS_GROUP_CODE].includes(marketingGroupCode);

  /** Если не свайп открыть карточку */
  const clickOrDrag = (e, item) => {
    const mouseUp = e.clientX;
    if (
      mouseUp < clientX.current + minSwipeDistance &&
      mouseUp > clientX.current - minSwipeDistance
    ) {
      handleDeviceCardClick(e, item);
    }
    clientX.current = null;
  };

  // Событие при клике на карточку
  const handleDeviceCardClick = (e, item: SoftlineFields): void => {
    onSoftlineCardClick(item);
    e.preventDefault();
  };

  // Ширина карточки продукта
  const PRODUCT_ITEM_WIDTH = useMemo(() => {
    if (!softlinesRef?.current?.clientWidth || isDesktop940) return 200;
    return softlinesRef?.current?.clientWidth - 64;
  }, [softlinesRef?.current?.clientWidth]);

  // Состояние позиции списка продуктов по оси X
  const [xPosition, setXPosition] = useState<number>(0);

  const index = useMemo(() => {
    return Math.abs(xPosition / PRODUCT_ITEM_WIDTH);
  }, [xPosition]);

  // Обработка клика по правой стрелке
  const onRightClick = () => {
    setXPosition((prevState) => prevState - PRODUCT_ITEM_WIDTH);
  };
  // Обработка клика по левой стрелке
  const onLeftClick = () => {
    setXPosition((prevState) => prevState + PRODUCT_ITEM_WIDTH);
  };
  // Флаг отображения стрелки справа
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);

  useEffect(() => {
    if (
      !deviceListRef.current?.offsetWidth ||
      !containerRef.current?.offsetWidth ||
      softlineSubscriptions.length <= 1
    )
      return;
    // Сколько карточек продуктов полностью влезает на экран
    const visibleAmount = Math.floor(
      containerRef.current.offsetWidth / PRODUCT_ITEM_WIDTH,
    );

    // Позиция ленты продуктов, после которой необходимо скрыть правый переключатель
    const isMaxXPosition =
      Math.abs(xPosition) >=
      (softlineSubscriptions.length - visibleAmount) * PRODUCT_ITEM_WIDTH;

    if (
      (deviceListRef.current.offsetWidth > containerRef.current.offsetWidth ||
        !isMaxXPosition) &&
      !showRightArrow
    )
      setShowRightArrow(true);
    if (
      (deviceListRef.current.offsetWidth < containerRef.current.offsetWidth ||
        isMaxXPosition) &&
      showRightArrow
    )
      setShowRightArrow(false);
  }, [xPosition, width, softlineSubscriptions]);

  // Значения точек налача и конца swipe
  const touch = { start: 0, end: 0 };
  // Событие при начале swipe
  const handleTouchStart = (e) => {
    touch.start = e.targetTouches[0].clientX;
  };
  // Событие при самом swipe
  const handleTouchMove = (e) => {
    touch.end = e.targetTouches[0].clientX;
  };
  // Обработка завершения swipe
  const handleTouchEnd = () => {
    // Если пользователь сделал touch без swipe - touchEnd будет равна 0
    if (!touch.end) return;
    if (touch.start - touch.end > SWIPE_AMOUNT && showRightArrow) {
      onRightClick();
      touch.end = 0;
    }
    if (touch.start - touch.end < -SWIPE_AMOUNT && xPosition < 0) {
      onLeftClick();
      touch.end = 0;
    }
  };

  const isHideSlide = (item) => {
    return !isDesktop940 && softlineSubscriptions.indexOf(item) !== index;
  };

  const noSubscriptions = (): JSX.Element => {
    return (
      <StyledNoSoftlines isBusinessProduct={isBusinessProduct}>
        {isBusinessProduct && (
          <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
            Антивирусное ПО недоступно на&nbsp;вашем договоре
          </Text>
        )}
        {softlineSubscriptions?.length === 0 && !isBusinessProduct && (
          <>
            {!isDesktop1280 && (
              <div className="catalog">
                <LinkWrapper href={SOFT_SLUG}>Каталог подписок</LinkWrapper>
              </div>
            )}
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              Выберите подходящую подписку и&nbsp;оформите заказ
              с&nbsp;бесплатным промо периодом на&nbsp;30&nbsp;дней.
            </Text>
            {isDesktop1280 && (
              <LinkWrapper href={SOFT_SLUG}>Выбрать подписку</LinkWrapper>
            )}
          </>
        )}
        {softlineSubscriptions?.length !== 0 && !isBusinessProduct && (
          <SoftlineCard
            key={softlineSubscriptions[0].key}
            item={(softlineSubscriptions[0] as unknown) as SoftlineFields}
            onDeviceCardMouseDown={(e) => mouseDownCoords(e)}
            onDeviceCardMouseUp={(e) =>
              clickOrDrag(e, softlineSubscriptions[0])
            }
            itemWidth={PRODUCT_ITEM_WIDTH}
          />
        )}
      </StyledNoSoftlines>
    );
  };

  const banner = useMemo(() => {
    return {
      isShow: isFewSubscriptions && isDesktop940,
      bannerData,
    };
  }, [bannerData, isFewSubscriptions, isDesktop940]);

  return (
    <span className="pab2cDevices__content">
      <StyledProductSelectorArrow
        onClick={onLeftClick}
        show={xPosition < 0}
        isLeft
      >
        <Icon icon={<Icons.ArrowIcon />} />
      </StyledProductSelectorArrow>
      <StyledSoftlinesList
        withBanner={banner.isShow}
        xPosition={xPosition}
        ref={containerRef}
        isBusinessProduct
      >
        {isFewSubscriptions ? (
          noSubscriptions()
        ) : (
          <span
            ref={deviceListRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <StyledSoftlinesCardGroup>
              {softlineSubscriptions?.length > 0 &&
                softlineSubscriptions?.map((item) => {
                  return (
                    <SoftlineCard
                      key={item.key}
                      item={(item as unknown) as SoftlineFields}
                      onDeviceCardMouseDown={(e) => mouseDownCoords(e)}
                      onDeviceCardMouseUp={(e) => clickOrDrag(e, item)}
                      itemWidth={PRODUCT_ITEM_WIDTH}
                      isHide={isHideSlide(item)}
                    />
                  );
                })}
            </StyledSoftlinesCardGroup>
          </span>
        )}
      </StyledSoftlinesList>
      <StyledProductSelectorArrow onClick={onRightClick} show={showRightArrow}>
        <Icon icon={<Icons.ArrowIcon />} />
      </StyledProductSelectorArrow>

      {banner.isShow && !banner.bannerData?.url && <StyledBanner />}

      {banner.isShow && banner.bannerData?.url && !isBusinessProduct && (
        <StyledBanner $imgUrl={banner.bannerData.url}>
          <H3 color={defaultTheme.colors.white}>
            {parseHtml(banner.bannerData.header)}
          </H3>
          <StyledBannerText color={defaultTheme.colors.white}>
            {parseHtml(banner.bannerData.mainText)}
          </StyledBannerText>
          <LinkWrapper href={banner.bannerData.link}>
            <Button styleType={ButtonStyleTypes.OUTLINE_BLACK}>
              {isDesktop1280
                ? banner.bannerData.buttonText
                : banner.bannerData.buttonTextMobile}
            </Button>
          </LinkWrapper>
        </StyledBanner>
      )}
    </span>
  );
};

export default observer(SoftlineSliderList);
