/** библиотеки */
import React, { FC } from 'react';
import { observer } from 'mobx-react';
/** типы */
import { ProductSelectorContentProps } from './types';
/** stores */
import { ContractProvider } from './store/useContractStore';
import ContractContent from './ContractContent';

/**
 * Блок «РМП. Мой договор»
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=732813699
 */
const Pab2cContract: FC<ProductSelectorContentProps> = ({
  content,
}: ProductSelectorContentProps): JSX.Element => {
  return (
    <ContractProvider blocks={content.blocks}>
      <ContractContent />
    </ContractProvider>
  );
};

export default observer(Pab2cContract);
