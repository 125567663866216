import { Instance, types } from 'mobx-state-tree';
import { toJS } from 'mobx';
import { SoftlineFieldsProps } from '../../types';
import { SoftlineFieldsModel } from '~/stores/models/SoftlineModel';

const SoftlineModel = types
  .model('SoftlineModel', {
    fieldsData: types.maybeNull(SoftlineFieldsModel),
  })
  .views((self) => ({
    get fields() {
      return toJS(self.fieldsData);
    },
    get type() {
      return toJS(self.fieldsData.type);
    },
    get singleCardId() {
      return toJS(self.fieldsData.singleCardId);
    },
    get tags() {
      return toJS(self.fieldsData.tags);
    },
    get features() {
      return toJS(self.fieldsData.features);
    },
    get imageSizes() {
      return toJS(self.fieldsData.imageSizes);
    },
    get enhancedFields() {
      return toJS(self.fieldsData.enhancedFields);
    },
  }));

const createStore = (fieldsData: SoftlineFieldsProps) => {
  return SoftlineModel.create({
    fieldsData,
  });
};
export type ISoftlineStore = Instance<typeof SoftlineModel>;
export default createStore;
