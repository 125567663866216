/** библиотеки */
import React, { FC, useEffect, useState } from 'react';
import Image from 'next/image';
import { observer } from 'mobx-react';

/** hooks */
import useDeepLinkChecker from '~/utils/useDeepLinkChecker';

/** стили */
import { StyledMobileSBP, LoaderWrapper } from './style';

/** компоненты библиотеки */
import {
  Text,
  defaultTheme,
  LinkButton,
  LeadingText,
  Autocomplete,
  Button,
  Loader,
  SidePage,
} from 'cordis-core-ui-planeta';
import LinkWrapper from '~/components/LinkWrapper';

/** типы */
import { BanksProps } from '~/components/Blocks/Templates/Payment/types';

/** утилиты */
import { cookies } from '~/api/api';
import { HooksTyping } from '~/utils/typeScriptHelpers';
import { getHeaderPhone } from '~/components/Header/utils';

/** константы */
import {
  COOKIES_CITY_DEAD_TIME_IN_DAYS,
  SELECTED_BANKS,
} from '~/constants/common';
import { NO_CHECK_URL_AMOUNT } from '../constants';
import { CLASS_NAME_SEARCH_BLOCK, DEEP_LINK_TEXT } from './constants';

/** stores */
import useMobileSBPStore from './store/useMobileSBPStore';
import { useRootStore } from '~/stores/RootStore';

const MobileSBP: FC = () => {
  const {
    selectedBank,
    setSelectedBank,
    isOpenSBPCards,
    deepLinkText,
    setDeepLinkText,
    waitingPage,
    setWaitingPage,
    payload,
    banksList,
    getConvertedBanksList,
    getConvertedSBPBanksList,
    setIsZeroing,
    setFetchAmount,
    isUseSBPBanksList,
    convertedBanksList,
    convertedSBPBanksList,
  } = useMobileSBPStore();
  const {
    cityStore: { city, contactPhones },
    sbpBanksStore: { sbpBanks },
  } = useRootStore();

  /** Вычисление единицы высоты viewport при его изменении */
  useEffect(() => {
    const setVhProperty = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    window.addEventListener('resize', setVhProperty);
    return () => window.removeEventListener('resize', setVhProperty);
  }, []);

  useEffect(() => {
    if (!isOpenSBPCards) return;
    if (isUseSBPBanksList && !convertedSBPBanksList.length)
      getConvertedSBPBanksList(sbpBanks);
    if (!isUseSBPBanksList && !convertedBanksList.length)
      getConvertedBanksList();
  }, [isOpenSBPCards]);

  /** Отфильтрованный список банков */
  // spread для исключения мутаций
  const [filteredBanksList, setFilteredBanksList] = useState([]);

  /** Флаг фокусировки поля поиска */
  const [onFocusSearch, setOnFocusSearch] = useState(false);

  /** Значение поля поиска */
  const [searchValue, setSearchValue] = useState('');

  /** Загрузка возможности оплаты */
  const [
    isLoadingPossibility,
    setIsLoadingPossibility,
  ]: HooksTyping<boolean> = useState<boolean>(false);

  const onIgnored = () => {
    setDeepLinkText(DEEP_LINK_TEXT.IGNORED);
  };

  const onFallback = () => {
    setDeepLinkText(DEEP_LINK_TEXT.FALLBACK);
  };

  const onReturn = () => {
    setDeepLinkText(DEEP_LINK_TEXT.RETURN);
  };

  const startChecking = useDeepLinkChecker({ onIgnored, onFallback, onReturn });

  /** Заголовок сайдпейджа */
  const getHeaderText = () => {
    if (waitingPage) {
      if (isLoadingPossibility) return 'Проверяем возможность оплаты';
      if (deepLinkText === DEEP_LINK_TEXT.RETURN) {
        /* yaspeller ignore:start */
        return 'Ожидаем\nпоступления\nплатежа';
        /* yaspeller ignore:end */
      }
      if (
        deepLinkText === DEEP_LINK_TEXT.FALLBACK ||
        deepLinkText === DEEP_LINK_TEXT.IGNORED
      )
        /* yaspeller ignore:start */
        return 'Оплата\nнедоступна';
      /* yaspeller ignore:end */
    } else {
      /* yaspeller ignore:start */
      return `Выберите банк\nдля${'\u00A0'}оплаты`;
      /* yaspeller ignore:end */
    }
    return '';
  };

  /** Номер телефона */
  const phone = getHeaderPhone(contactPhones, city);

  /** Форматирование номера телефона для ссылки */
  const phoneLink = phone.replace(/\D/g, '');

  const bankListSort = (prev: BanksProps, next: BanksProps) =>
    prev.sort - next.sort;

  const createFilterList = (selectedBanks: string[]) => {
    if (!selectedBanks) return;
    setFilteredBanksList(
      [...banksList].map((item) => {
        if (item.schema === selectedBanks[0]) return { ...item, sort: -2 };
        if (item.schema === selectedBanks[1]) return { ...item, sort: -1 };
        return item;
      }),
    );
  };

  /** Событие при выборе банка */
  const bankSelection = async (bank) => {
    setIsLoadingPossibility(true);
    setWaitingPage(true);
    startChecking();
    setSelectedBank(bank);

    const cookiesDeadTime: Date = new Date();
    cookiesDeadTime.setDate(
      cookiesDeadTime.getDate() + COOKIES_CITY_DEAD_TIME_IN_DAYS,
    );

    const banks = cookies.get(SELECTED_BANKS) || [];
    banks.unshift(bank.schema);
    // В случае выбора банка из списка выбранных - поменять их местами
    const selectedBanks = [...new Set(banks)].slice(0, 2) as string[];

    cookies.set(SELECTED_BANKS, JSON.stringify(selectedBanks), {
      path: '/',
      expires: cookiesDeadTime,
    });

    setSearchValue('');
    setOnFocusSearch(false);
    createFilterList(selectedBanks);
  };

  useEffect(() => {
    if (!banksList?.length) return;
    if (cookies.get(SELECTED_BANKS)) {
      createFilterList(cookies.get(SELECTED_BANKS));
      return;
    }
    setFilteredBanksList([...banksList]);
  }, [banksList]);

  const getBankList = (selectedBanks: string[]) => {
    if (!selectedBanks) return banksList;
    return [...banksList].map((item) => {
      if (item.schema === selectedBanks[0]) return { ...item, sort: -2 };
      if (item.schema === selectedBanks[1]) return { ...item, sort: -1 };
      return item;
    });
  };

  /** Обработчик ввода */
  const onChangeSearch = (value) => {
    const bankList = getBankList(cookies.get(SELECTED_BANKS));
    setSearchValue(value);
    const list = bankList.filter((item) =>
      item.bankName.trim().toLowerCase().includes(value.trim().toLowerCase()),
    );
    setFilteredBanksList(value ? list : bankList);
  };

  const onBlur = () => {
    setOnFocusSearch(false);
    if (!searchValue) createFilterList(cookies.get(SELECTED_BANKS));
  };

  /**
   * вызывает клик по кнопке открытия чата
   */
  const openChat = (): void => {
    const button = document.getElementsByClassName(
      'sherlock-widget',
    )[0] as HTMLElement;
    if (button) button.click();
  };
  const closeHandler = () => {
    setIsZeroing(true);
    setSearchValue('');
    setFilteredBanksList([...banksList]);
  };

  useEffect(() => {
    if (deepLinkText) {
      setIsLoadingPossibility(false);
    }
  }, [deepLinkText]);

  return (
    <SidePage
      show={isOpenSBPCards}
      headerText={getHeaderText()}
      onCloseClick={closeHandler}
    >
      {!banksList?.length ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <StyledMobileSBP
          $isSoftKeyShowed={onFocusSearch}
          waitingPage={waitingPage}
          ignoredPage={
            deepLinkText === DEEP_LINK_TEXT.FALLBACK ||
            deepLinkText === DEEP_LINK_TEXT.IGNORED
          }
          isLoadingPossibility={isLoadingPossibility}
        >
          {waitingPage ? (
            <>
              {isLoadingPossibility ? (
                <>
                  <Text lineHeight="24px">Нужно немного подождать...</Text>
                  <div className="mobile-sbp__loader">
                    <Loader />
                  </div>
                </>
              ) : (
                <>
                  <Text className="mobile-sbp__info-text" lineHeight="24px">
                    {deepLinkText}
                  </Text>
                  <div className="mobile-sbp__selected-bank">
                    <Image
                      src={`data:image/png;base64, ${selectedBank?.logo}`}
                      alt="logo"
                      width={selectedBank.width * 2}
                      height={selectedBank.height * 2}
                      quality={100}
                    />
                    <LeadingText color={defaultTheme.colors.black}>
                      {selectedBank?.bankName}
                    </LeadingText>
                  </div>
                  <LinkButton
                    onClick={() => {
                      setDeepLinkText('');
                      setWaitingPage(false);
                      setFetchAmount(NO_CHECK_URL_AMOUNT);
                    }}
                  >
                    Выбрать другой банк
                  </LinkButton>
                </>
              )}
            </>
          ) : (
            <>
              {!onFocusSearch && !searchValue && (
                <Text className="mobile-sbp__info-text" lineHeight="24px">
                  После выбора банка у&nbsp;вас откроется банковское приложение,
                  где вы&nbsp;сможете завершить оплату.
                </Text>
              )}
              <div className={CLASS_NAME_SEARCH_BLOCK}>
                <Autocomplete
                  showSearchIcon
                  items={banksList}
                  text={searchValue}
                  placeholder="Поиск вашего банка"
                  onInputChange={(value) => onChangeSearch(value)}
                  onInputFocus={() => setOnFocusSearch(true)}
                  onBlur={onBlur}
                  hideOptions
                />
              </div>
              {filteredBanksList?.length > 0 ? (
                <div className="mobile-sbp__list-of-banks">
                  {filteredBanksList.sort(bankListSort).map((item) => {
                    return (
                      <LinkButton
                        href={payload?.replace('https', item.schema)}
                        onClick={() => bankSelection(item)}
                        color={defaultTheme.colors.black}
                        key={item.schema}
                      >
                        <div className="mobile-sbp__img-wrapper">
                          <Image
                            src={`data:image/png;base64, ${item.logo}`}
                            alt="logo"
                            width={item.width}
                            height={item.height}
                            quality={100}
                          />
                        </div>
                        <Text
                          className="mobile-sbp__list-of-banks__bank-name"
                          lineHeight="24px"
                        >
                          {item.bankName}
                        </Text>
                      </LinkButton>
                    );
                  })}
                </div>
              ) : (
                <div>
                  <Text
                    className="mobile-sbp__not-found-text"
                    lineHeight="24px"
                  >
                    Если вашего банка нет в{'\u00A0'}списке, вероятно, он не
                    {'\u00A0'}поддерживает подписку СБП.
                    <br />
                    Если вы уверены в{'\u00A0'}обратном, сообщите нам по
                    {'\u00A0'}телефону{' '}
                    <LinkWrapper href={`tel:${phoneLink}`} target="_self">
                      <Text
                        className="mobile-sbp__not-found-text"
                        lineHeight="24px"
                        color={defaultTheme.colors.planeta}
                      >
                        {phone}{' '}
                      </Text>
                    </LinkWrapper>
                    или{' '}
                    <LinkButton
                      color={defaultTheme.colors.planeta}
                      onClick={openChat}
                    >
                      напишите в{'\u00A0'}чат
                    </LinkButton>
                    .
                  </Text>
                </div>
              )}
            </>
          )}
          {waitingPage && !isLoadingPossibility && (
            <LinkWrapper
              target="_self"
              href={payload?.replace('https', selectedBank.schema)}
            >
              <Button className="mobile-sbp__button-open-app">
                Открыть приложение
              </Button>
            </LinkWrapper>
          )}
        </StyledMobileSBP>
      )}
    </SidePage>
  );
};

export default observer(MobileSBP);
