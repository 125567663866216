/** Libraries */
import { FC, useMemo, useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
/** Components */
import Root, {
  StyledHeader,
  StyledIPList,
  StyledLoaderWrapper,
  StyledSwitcher,
} from './styles';
import { defaultTheme, Loader, SidePage, Text } from 'cordis-core-ui-planeta';
import Portal from '~/components/Portal/Portal';
import IPv6StateWizardFooter from './IPv6StateWizard/IPv6StateWizardFooter';
import IPv6StateWizard from './IPv6StateWizard/IPv6StateWizard';
/** constants */
import { HELP_PLANETA_POPULAR_URL } from '~/constants/common';
/** Types */
import { TITLE_TEXT, SubnetsType } from './types';
import { ResourceNetProps } from '../Contract/types';
/** Constants */
import { OPERATING_STATE } from '~/components/Blocks/Templates/Pab2c/Contract/constants';
import { VIEW_TYPES_IP_V6 } from './IPv6StateWizard/constants';
import {
  OPEN_IPV6_SP_ACTION,
  OPEN_IPV6_SP_AGREEMENT,
} from '~/components/AuthWizard/constants';
/** api */
import { getResourceNetAll, setDHCPIPv6Flag } from '~/api/apiPab2c';
/** utils */
import { HooksTyping } from '~/utils/typeScriptHelpers';
import LinkWrapper from '~/components/LinkWrapper';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

/** Блок "Интернет" - https://ckb.itmh.ru/pages/viewpage.action?pageId=732821221 */
const pab2cInternet: FC = () => {
  const {
    contractStateStore: { contractState, isLoading },
    contractInfoStore: { isFvno },
    summaryDataStore: { speedBaseText },
    authStore: { isTemporaryTokenAuth, isLoadingAuth, isAuth },
    vacationStore: { isOrdered },
  } = useRootStore();

  const {
    openSPAfterAuthorizationState,
    setOpenSPAfterAuthorizationState,
  } = useMakeAuthStore();

  /** Список подсетей IPv4 и IPv6, прикреплённых к договору в данный момент */
  const [listOfContractSubnets, setListOfContractSubnets]: HooksTyping<
    ResourceNetProps[]
  > = useState<ResourceNetProps[]>(null);

  const [isIpv6StateWizardShow, setIsIpv6StateWizardShow] = useState<boolean>(
    false,
  );
  const [viewTypeIpV6, setViewTypeIpV6] = useState<VIEW_TYPES_IP_V6>(
    VIEW_TYPES_IP_V6.FORM,
  );
  const [isIpv6StateLoading, setIsIpv6StateLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  const ipSubnets: SubnetsType = useMemo(() => {
    return listOfContractSubnets?.reduce(
      (acc, item) => {
        if (item.isIpv6 && item.ipv6DhcpEnabled) acc.ipv6Subnets.push(item);
        if (!item.isIpv6) acc.ipv4Subnets.push(item);
        return acc;
      },
      {
        ipv6Subnets: [],
        ipv4Subnets: [],
      },
    );
  }, [listOfContractSubnets]);

  const simByMinResource = useMemo(
    () =>
      listOfContractSubnets?.length
        ? Math.min(...listOfContractSubnets?.map((item) => item.simId))
        : null,
    [listOfContractSubnets],
  );

  const handleIpv6StateSubmit = useCallback(async () => {
    setIsIpv6StateLoading(true);

    try {
      await setDHCPIPv6Flag(simByMinResource, !ipSubnets?.ipv6Subnets?.length);
      await getListOfContractSubnets();
      setViewTypeIpV6(VIEW_TYPES_IP_V6.SUCCESS);
      setOpenSPAfterAuthorizationState(null);
    } catch (e) {
      if (e.statusCode === 401) {
        setOpenSPAfterAuthorizationState(OPEN_IPV6_SP_ACTION);
        return;
      }
      setOpenSPAfterAuthorizationState(null);
      setViewTypeIpV6(VIEW_TYPES_IP_V6.ERROR);
      setError('Ошибка выполнения запроса');
    } finally {
      setIsIpv6StateLoading(false);
    }
  }, [listOfContractSubnets]);

  const isZombie = contractState === OPERATING_STATE.STRAY;

  const handleIpv6StateWizardClick = () => {
    setViewTypeIpV6(VIEW_TYPES_IP_V6.FORM);
    setError(null);
    setIsIpv6StateWizardShow(!isIpv6StateWizardShow);
  };

  const getListOfContractSubnets = async (): Promise<void> => {
    try {
      const res = await getResourceNetAll();
      setListOfContractSubnets(res);
    } catch (e) {
      console.error('getListOfContractSubnets', e);
    }
  };

  useEffect(() => {
    if (isAuth) {
      getListOfContractSubnets();
    } else {
      setListOfContractSubnets([]);
    }
  }, [isAuth]);

  useEffect(() => {
    if (!isAuth || !simByMinResource) return;
    if (openSPAfterAuthorizationState === OPEN_IPV6_SP_AGREEMENT) {
      handleIpv6StateWizardClick();
      return;
    }
    if (openSPAfterAuthorizationState === OPEN_IPV6_SP_ACTION) {
      setIsIpv6StateWizardShow(true);
      handleIpv6StateSubmit();
    }
  }, [isAuth, simByMinResource]);

  const getIpv6StateTitle = (): string => {
    return ipSubnets?.ipv6Subnets?.length
      ? 'Отключение IPv6'
      : 'Включение IPv6';
  };

  const ipSwitcher = (): JSX.Element => {
    if (isZombie || isFvno) return null;

    const getSwitcherTitle = () => {
      switch (contractState) {
        case OPERATING_STATE.DREGS:
          return TITLE_TEXT.DREGS;
        case OPERATING_STATE.NEW:
          return TITLE_TEXT.NEW;
        case OPERATING_STATE.CLIENT_BLOCK:
          return isOrdered ? TITLE_TEXT.VACATION : null;
        default:
          return null;
      }
    };

    return (
      <StyledSwitcher
        title={getSwitcherTitle()}
        checked={
          ipSubnets?.ipv6Subnets?.length
            ? !isIpv6StateWizardShow
            : isIpv6StateWizardShow
        }
        onClick={handleIpv6StateWizardClick}
        disabled={
          contractState === OPERATING_STATE.DREGS ||
          contractState === OPERATING_STATE.NEW ||
          isOrdered
        }
      >
        {ipSubnets?.ipv6Subnets?.length ? 'IPv6 включён' : 'IPv6 выключен'}
        <LinkWrapper href={HELP_PLANETA_POPULAR_URL}>
          <Text color={defaultTheme.colors.planeta}>Что такое IPv6</Text>
        </LinkWrapper>
      </StyledSwitcher>
    );
  };

  const ipList = (subnets: SubnetsType[keyof SubnetsType]): JSX.Element => {
    if (isZombie || !subnets?.length) return null;
    const isIPv4 = subnets.every((ip) => !ip.isIpv6);
    return (
      <StyledIPList>
        {subnets.length > 0 && (
          <>
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              {isIPv4 ? 'IPv4 адрес' : 'IPv6 адреса'}
            </Text>
            {subnets.map((ip) => (
              <Text key={ip.presentContent} lineHeight="24px">
                {ip.presentContent}
              </Text>
            ))}
          </>
        )}
      </StyledIPList>
    );
  };

  if (isLoadingAuth && isLoading) {
    return (
      <Root>
        <Text>Интернет</Text>
        <StyledLoaderWrapper>
          <Loader />
        </StyledLoaderWrapper>
      </Root>
    );
  }

  return (
    <>
      <Root>
        <Text>Интернет</Text>
        <StyledHeader>{isZombie ? '0 мб/сек' : speedBaseText}</StyledHeader>
        {ipSwitcher()}
        {ipList(ipSubnets?.ipv4Subnets)}
        {ipList(ipSubnets?.ipv6Subnets)}
      </Root>

      <Portal>
        <SidePage
          show={isIpv6StateWizardShow}
          width="832px"
          headerText={
            viewTypeIpV6 === VIEW_TYPES_IP_V6.FORM && !isIpv6StateLoading
              ? getIpv6StateTitle()
              : null
          }
          onCloseClick={() => {
            setIsIpv6StateWizardShow(false);
            setOpenSPAfterAuthorizationState(null);
          }}
          footerContainer={
            viewTypeIpV6 === VIEW_TYPES_IP_V6.FORM &&
            !isIpv6StateLoading && (
              <IPv6StateWizardFooter
                handleSubmit={handleIpv6StateSubmit}
                viewType={viewTypeIpV6}
                isActionLoading={isIpv6StateLoading}
                isTemporaryTokenAuth={isTemporaryTokenAuth}
              />
            )
          }
          isOnlyMobileFooter
          removeScrollBar
        >
          <IPv6StateWizard
            sim={simByMinResource}
            isIpV6Enabled={!!ipSubnets?.ipv6Subnets?.length}
            viewType={viewTypeIpV6}
            setViewType={setViewTypeIpV6}
            error={error}
            setError={setError}
            isIpv6StateLoading={isIpv6StateLoading}
          />
        </SidePage>
      </Portal>
    </>
  );
};

export default observer(pab2cInternet);
