/** libraries */
import { FC, useEffect, useState } from 'react';
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledFooter } from '../../styles';
/** api */
import { deleteContact } from '~/api/apiPab2c';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useContactsAndNotificationsStore from '../../store/useContactsAndNotificationsStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
/** constants */
import { OPEN_DELETE_CONTACT } from '~/components/AuthWizard/constants';

const DeletingContactFooter: FC = () => {
  const {
    authStore: { isTemporaryTokenAuth, isAuth },
  } = useRootStore();

  const {
    getAllContacts,
    deletingContactStore: {
      deleteContactInfo,
      setIsShowDeletingContact,
      setResult,
    },
  } = useContactsAndNotificationsStore();
  const {
    openSPAfterAuthorizationState,
    setOpenSPAfterAuthorizationState,
  } = useMakeAuthStore();

  useEffect(() => {
    if (!isAuth && !deleteContactInfo) return;
    if (openSPAfterAuthorizationState === OPEN_DELETE_CONTACT) {
      deletingContact();
    }
  }, [isAuth]);
  /** Loader удаления контакта */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** Удаление контакта */
  const deletingContact = async () => {
    setIsLoading(true);
    try {
      await deleteContact(deleteContactInfo.id);
      getAllContacts();
      setIsShowDeletingContact(false);
      setOpenSPAfterAuthorizationState(null);
    } catch (e) {
      if (e.statusCode === 401) {
        setOpenSPAfterAuthorizationState(OPEN_DELETE_CONTACT);
        return;
      }
      setOpenSPAfterAuthorizationState(null);
      setResult({ isResult: true, isCorrect: false });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledFooter>
      <Button
        onClick={deletingContact}
        loading={isLoading}
        disabled={isTemporaryTokenAuth}
      >
        Удалить
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(DeletingContactFooter);
