/** библиотеки */
import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { isMobile } from 'react-device-detect';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';

/* Утилиты */
import { scrollToBlockById } from '~/components/Blocks/Shared/Shared.utils';
import {
  banksListImageConverter,
  checkUrlAccessMobile,
} from '../Payment/utils';
import { HooksTyping } from '~/utils/typeScriptHelpers';

/** api */
import {
  cardPaymentBindAutoPayment,
  cardPaymentUnBindAutoPayment,
  checkCardPaymentStateForCardBinding,
  getCardPaymentLimit,
  getRecommendAutoPaymentInfo,
  getSbpPaymentLimit,
  sbpCheckBinding,
  sbpRegisterBinding,
  setRegisterCardPaymentWithCardBinding,
} from '~/api/api';

/** константы */
import {
  AUTO_PAYMENT_BLOCK_ID,
  DEFAULT_AMOUNT,
  VIEW_TYPES,
} from '~/components/Blocks/Shared/Autopayment/AutoPaymentWizard/constants';
import {
  CONTRACT_NOT_FOUND_TYPE,
  DEFAULT_FORM_ERROR_STATE,
  ERROR_MESSAGES,
  NEW_CARD,
} from '~/components/Blocks/Templates/Payment/constants';
import { NEW_ACCOUNT, PAYMENT_QP } from '../PaymentResult/constants';
import { AUTOPAYMENT_MIN_AMOUNT } from '~/components/Blocks/Shared/Autopayment/constants';

/** Типы */
import { StateFormErrorProps, UseAutoPaymentReturnProps } from './types';
import { AmountProps } from '~/components/Blocks/Shared/Autopayment/AutoPaymentWizard/types';
import { AccountProps } from '../../Shared/CardAndSbpPaymentSelect/interfaces';
import { BanksProps } from '../Payment/types';
import {
  SbpBindingCheckInfoProps,
  SbpInfo,
  SbpRegisterBindingInfo,
} from '../PaymentResult/interfaces';

/** компоненты библиотеки */
import { OptionProp } from 'cordis-core-ui-planeta';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileSBPStore from '../Payment/MobileSBP/store/useMobileSBPStore';

/**
 * Специальный хук логики автоплатежа
 * @return {UseAutoPaymentReturnProps}
 */
export const useAutoPayment = (): UseAutoPaymentReturnProps => {
  const {
    paymentInfoStore: { getSbpBindings, getPaymentCards },
    autoPaymentInfoStore: {
      autoPaymentData,
      setAutoPaymentData,
      getAutoPaymentData,
    },
    sbpBanksStore: { sbpBanks },
    authStore: { isAuth, auth },
  } = useRootStore();
  const {
    setIsOpenSBPCards,
    waitingPage,
    setPayload,
    fetchAmount,
    setFetchAmount,
    resetStore,
    setTimerId,
    timerId,
    isZeroing,
    setIsUseSBPBanksList,
  } = useMobileSBPStore();

  useEffect(() => {
    /** TODO: почему здесь? */
    if (isAuth) {
      getSbpBanks();
    }
  }, [isAuth]);

  // Флаг подключения автоплатежа на договоре
  const isAutoPaymentOn = autoPaymentData?.isActive;
  // Код шаблона отображения по умолчанию
  const getDefaultView: number = isAutoPaymentOn
    ? VIEW_TYPES.UNBIND
    : VIEW_TYPES.BIND;
  // Флаг загрузки
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Флаг отображения визарда
  const [isWizardShow, setIsWizardShow] = useState<boolean>(false);
  // Код текущего шаблона отображения
  const [viewType, setViewType] = useState<VIEW_TYPES>(getDefaultView);
  // Флаг загрузки в футере
  const [isLoadingFooter, setIsLoadingFooter] = useState<boolean>(false);
  // Сумма
  const [amount, setAmount] = useState<AmountProps>(DEFAULT_AMOUNT);
  // Текст ошибки
  const [errorMessage, setErrorMessage] = useState<ERROR_MESSAGES | null>(null);
  // Выбранная карта
  const [card, setCard] = useState<OptionProp & AccountProps>(null);
  // Авто подсчёт checkbox
  const [isAutoCount, setIsAutoCount] = useState<boolean>(true);
  // Ошибки формы
  const [stateFormError, setFormErrorState] = useState<StateFormErrorProps>(
    DEFAULT_FORM_ERROR_STATE,
  );
  // Флаг вызова авто оплаты
  const [isSubmitTriggered, setIsSubmitTriggered] = useState<boolean>(false);
  /** Время qr кода истекло */
  const [timeIsUp, setTimeIsUp] = useState<boolean>(false);
  // Для чтения параметров из url
  const router = useRouter();

  useEffect(() => {
    if (isAuth) {
      if ([VIEW_TYPES.BIND, VIEW_TYPES.UNBIND].includes(viewType))
        setViewType(
          autoPaymentData?.isActive ? VIEW_TYPES.UNBIND : VIEW_TYPES.BIND,
        );
    }
  }, [autoPaymentData]);

  /** Заголовок визарда */
  const getWizardTitle = (): string => {
    switch (viewType) {
      case VIEW_TYPES.UNBIND:
        return 'Настройки автоплатежа';
      case VIEW_TYPES.BIND:
        return 'Подключение автоплатежа';
      case VIEW_TYPES.LINKING_ACCOUNT:
        return 'Привязка счёта СБП';
      default:
        return null;
    }
  };

  // Проверит информацию о состоянии платежа, если привязывали карту
  const checkPaymentStateForCardBinding = async (
    orderId: string,
    amountData: number,
    isAuto: boolean,
  ) => {
    try {
      setIsLoading(true);
      const bindCard = await checkCardPaymentStateForCardBinding(orderId);
      await getPaymentCards();
      await getAutoPaymentData();
      if (bindCard) {
        await cardPaymentBind(bindCard.cardBindingId, amountData, isAuto, null);
        setViewType(VIEW_TYPES.BIND_SUCCESS);
      }
      scrollToBlockById(AUTO_PAYMENT_BLOCK_ID);
      setIsLoading(false);
    } catch (error) {
      setViewType(VIEW_TYPES.LINKING_CARD_FAILED);
    }
    return null;
  };

  /** Подключает/изменяет автоплатёж для договора */
  const cardPaymentBind = async (
    cardBindId: number,
    amountCard: number,
    isAutoPayment: boolean,
    isSbp?: boolean,
  ) => {
    try {
      setIsLoading(true);
      if (router.query.contract) {
        const paymentCheck = await getRecommendAutoPaymentInfo(
          router.query.contract as string,
        );
        if (!paymentCheck.isAutoPaymentBindRecommended) return;
      }

      const res = await cardPaymentBindAutoPayment(
        cardBindId,
        amountCard,
        isAutoPayment,
      );
      if (isSbp) {
        getSbpBindings();
      }
      setViewType(VIEW_TYPES.BIND_SUCCESS);
      setAutoPaymentData(res);
    } catch (e) {
      const err = e.errorMessage ? JSON.parse(e.errorMessage) : {};
      if (err.Type === CONTRACT_NOT_FOUND_TYPE) {
        setErrorMessage(ERROR_MESSAGES.CONTRACT_NOT_FOUND);
        return;
      }
      setErrorMessage(ERROR_MESSAGES.ERROR);
      setViewType(VIEW_TYPES.BIND_FAILED);

      if (router.query?.bindAutoPayment)
        router.replace(
          {
            query: { ...router.query, bindAutoPayment: 'failed' },
          },
          undefined,
          {
            shallow: true,
          },
        );
    } finally {
      setIsLoading(false);
    }
  };

  /** Отключает автоплатёж для договора */
  const unbindAutoPayment = async () => {
    try {
      setIsLoadingFooter(true);
      const data = await cardPaymentUnBindAutoPayment();
      if (data) {
        setIsLoadingFooter(false);
        setViewType(VIEW_TYPES.UNBIND_SUCCESS);
        await getAutoPaymentData();
        setAmount(DEFAULT_AMOUNT);
        setIsAutoCount(true);
      }
    } catch (error) {
      setViewType(VIEW_TYPES.UNBIND_FAILED);
    }
  };

  /** Подключение автоплатежа с привязанной картой */
  const bindingWithCard = async (id?: number, isSbp?: boolean) => {
    try {
      await cardPaymentBind(
        id ?? card.value,
        (amount.forSend as unknown) as number,
        isAutoCount,
        isSbp,
      );
      setIsLoadingFooter(false);
    } catch (e) {
      setErrorMessage(ERROR_MESSAGES.ERROR);
      setIsLoadingFooter(false);
    }
  };

  /** Подключение автоплатежа с привязкой карты */
  const bindingWithoutCard = async (
    contractToken?: string,
    contractIdFromPaymentCheck?: number,
  ) => {
    const { href, search } = window.location;
    const baseUrl = search ? href.slice(0, href.indexOf(search)) : href;

    const getBackUrl = () => {
      if (!contractIdFromPaymentCheck)
        return `${baseUrl}?contract=${
          auth.contractName
        }&isAutoPayment=${true}&amount=${
          amount.forSend
        }&hasAutoCalc=${isAutoCount}&showAutopayment=${true}`;

      return `${baseUrl}?contractId=${contractIdFromPaymentCheck}&hasCardBinding=${true}&amount=${
        amount.forSend
      }&bindAutoPayment=${true}&hasAutoCalc=${isAutoCount}`;
    };

    try {
      const registerCardPaymentWithCardBinding = await setRegisterCardPaymentWithCardBinding(
        minAmountValue,
        encodeURIComponent(getBackUrl()),
        contractToken,
      );
      if (registerCardPaymentWithCardBinding.authCode === 'Ok') {
        window.location.assign(registerCardPaymentWithCardBinding.formUrl);
      }
    } catch (e) {
      setErrorMessage(ERROR_MESSAGES.ERROR);
      setIsLoadingFooter(false);
    }
  };

  /** Данные о привязке счёта */
  const [sbpBindingInfo, setSbpBindingInfo] = useState<
    SbpInfo | SbpRegisterBindingInfo
  >(null);

  /** Результат привязки счёта сбп */
  const [
    sbpBindingCheckInfo,
    setSbpBindingCheckInfo,
  ] = useState<SbpBindingCheckInfoProps>(null);

  // Список банков СБП c подпиской
  const [convertedSbpBanksList, setConvertedSbpBanksList]: HooksTyping<
    BanksProps[]
  > = useState<BanksProps[]>(null);

  /** Действие по доступности адреса оплаты */
  const onUrlAccess = () => {
    setIsOpenSBPCards(true);
  };

  /** Регистрирует qr-код для оплаты с созданием подписки */
  const registerSbpPaymentWithBinding = async () => {
    try {
      const res = await sbpRegisterBinding();
      if (isMobile && res.payload) {
        setIsUseSBPBanksList(true);
        setPayload(res.payload);
        setSbpBindingInfo(res);
        await checkUrlAccessMobile(
          res.payload,
          fetchAmount,
          setFetchAmount,
          null,
          onUrlAccess,
        );
      }
      if (!isMobile && res.qrImage) {
        setSbpBindingInfo(res);
        setViewType(VIEW_TYPES.LINKING_ACCOUNT);
      }
    } catch (e) {
      console.error('sbpRegisterBinding', e);
    }
  };

  /** Проверка статуса подписки */
  const checkSbpBinding = async (sbpSubscriptionId: string) => {
    try {
      const res = await sbpCheckBinding(sbpSubscriptionId);
      if (res.sbpSubscriptionStatus === 'Subscribed') {
        setSbpBindingCheckInfo(res);
        bindingWithCard(res.paymentBindingId, true);
        getSbpBindings();
        if (waitingPage) {
          resetStore();
          setIsLoadingFooter(false);
        }
      }
    } catch (e) {
      setViewType(VIEW_TYPES.LINKING_ACCOUNT_FAILED);
    }
  };

  /** Сбрасываем состояния, при закрытии СП MobileSBP */
  useEffect(() => {
    if (isZeroing) {
      setTimeIsUp(false);
      setIsLoadingFooter(false);
      setSbpBindingInfo(null);
      resetStore();
    }
  }, [isZeroing]);

  /** Список банков с подпиской */
  const getSbpBanks = async () => {
    const res = await banksListImageConverter(sbpBanks);
    setConvertedSbpBanksList(res);
  };

  // Проверка привязки СБП каждые 3 секунды
  useEffect(() => {
    if (
      sbpBindingInfo?.qrId &&
      !sbpBindingCheckInfo &&
      (waitingPage || !isMobile)
    ) {
      (async () => {
        const id = setInterval(() => {
          checkSbpBinding(
            (sbpBindingInfo as SbpRegisterBindingInfo)?.subscriptionId,
          );
        }, 3000);
        setTimerId((id as unknown) as NodeJS.Timeout);
      })();
    }
    if (!waitingPage && isMobile && timerId) clearInterval(timerId);
    if (sbpBindingInfo && sbpBindingCheckInfo) {
      clearInterval(timerId);
    }
    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [sbpBindingInfo, sbpBindingCheckInfo, waitingPage]);

  /** Время таймера сбп qr-кода вышло */
  useEffect(() => {
    if (timeIsUp) {
      setTimeIsUp(false);
      setIsLoadingFooter(false);
      setViewType(VIEW_TYPES.LINKING_ACCOUNT_FAILED);
    }
  }, [timeIsUp]);

  /** Проверка карты при подключении автоплатежа */
  const bindAutoPayment = (
    idAccount?: number,
    contractToken?: string,
    contractIdFromPaymentCheck?: number,
  ) => {
    try {
      setIsSubmitTriggered(true);
      setIsLoadingFooter(true);
      const errorState = validateForm();
      if (errorState.amount.error) {
        setIsLoadingFooter(false);
        return;
      }
      if (
        (card && card.label !== NEW_ACCOUNT && card.label !== NEW_CARD) ||
        idAccount
      )
        bindingWithCard(idAccount);
      if (
        (!idAccount && contractIdFromPaymentCheck) ||
        ((!card || card?.label === NEW_CARD) && card.isCard)
      )
        bindingWithoutCard(contractToken, contractIdFromPaymentCheck);
      if (card?.label === NEW_ACCOUNT) {
        registerSbpPaymentWithBinding();
      }
    } catch (error) {
      setErrorMessage(ERROR_MESSAGES.ERROR_BIND_CARD);
      setIsLoadingFooter(false);
    }
  };

  /** Валидация данных */
  const validateForm = (): StateFormErrorProps => {
    const minPaymentAmount =
      autoPaymentData?.minPayment ?? AUTOPAYMENT_MIN_AMOUNT;
    const isCorrectAmount = Number(amount?.forSend) >= minPaymentAmount;

    return {
      amount: {
        error: !isCorrectAmount,
      },
    };
  };

  useEffect(() => {
    if (isSubmitTriggered) {
      const errorState = validateForm();

      setFormErrorState(errorState);
    }
  }, [amount, isSubmitTriggered]);

  /**
   * Обработка результата платежа
   */
  useEffect(() => {
    if (
      !router.isReady ||
      (router.isReady &&
        (!router.query?.[PAYMENT_QP.planetaOrderId] ||
          !router.query.isAutoPayment))
    )
      return;
    checkPaymentStateForCardBinding(
      router.query?.[PAYMENT_QP.planetaOrderId] as string,
      (router.query.amount as unknown) as number,
      (router.query.hasAutoCalc as unknown) as boolean,
    );
  }, [router.isReady]);

  /** Получает дату */
  const getDate = (dateString: string, dateFormat = 'd MMMM yyyy'): string => {
    const dateISO = parseISO(dateString);
    return format(dateISO, dateFormat, { locale: ru });
  };

  /** Минимальное значение первого платежа для карты */
  const [minAmountCardValue, setMinAmountCardValue] = useState<number>(null);
  const getCardLimit = async () => {
    try {
      const res = await getCardPaymentLimit();
      setMinAmountCardValue(res.minAmount);
    } catch (e) {
      console.error('getCardPaymentLimit', e);
    }
  };
  /** Минимальное значение первого платежа для сбп */
  const [minAmountSbpValue, setMinAmountSbpValue] = useState<number>(null);
  const getSbpLimit = async () => {
    try {
      const res = await getSbpPaymentLimit();
      setMinAmountSbpValue(res.minAmount);
    } catch (e) {
      console.error('getSbpPaymentLimit', e);
    }
  };

  const limitAmountValues = () => {
    if (!isWizardShow) return;
    if (card?.isCard && !minAmountCardValue) {
      getCardLimit();
    }
    if (!card?.isCard && !minAmountSbpValue) {
      getSbpLimit();
    }
  };
  useEffect(() => {
    limitAmountValues();
  }, [card]);

  /** Минимальное значение первого платежа */
  const minAmountValue = useMemo(() => {
    if (card?.isCard && minAmountCardValue) return minAmountCardValue;
    return minAmountSbpValue;
  }, [minAmountCardValue, minAmountSbpValue]);

  /** Меняет SidePage при открытии */
  useEffect(() => {
    if (isWizardShow) {
      setViewType(getDefaultView);
      setIsSubmitTriggered(false);
    }
  }, [isWizardShow]);

  const openWizard = () => {
    router.replace(
      {
        query: { ...router.query, showAutopayment: true },
      },
      undefined,
      {
        shallow: true,
      },
    );
  };

  const closeWizard = () => {
    setIsWizardShow(false);
    setErrorMessage(null);
    setIsLoadingFooter(false);

    const qParams = { ...router?.query };
    delete qParams.showAutopayment;
    delete qParams.isAutoPayment;
    delete qParams.contract;
    delete qParams.amount;
    delete qParams.hasAutoCalc;
    delete qParams.planetaOrderId;
    delete qParams.bindAutoPayment;

    router.replace(
      {
        query: { ...qParams },
      },
      undefined,
      {
        shallow: true,
      },
    );
  };

  return {
    isLoading,
    setIsLoading,
    isWizardShow,
    setIsWizardShow,
    viewType,
    setViewType,
    isLoadingFooter,
    amount,
    setAmount,
    errorMessage,
    setErrorMessage,
    setCard,
    isAutoCount,
    setIsAutoCount,
    getWizardTitle,
    unbindAutoPayment,
    bindAutoPayment,
    cardPaymentBind,
    getDate,
    stateFormError,
    openWizard,
    closeWizard,
    convertedSbpBanksList,
    setConvertedSbpBanksList,
    sbpBindingInfo,
    setSbpBindingInfo,
    setTimeIsUp,
    setSbpBindingCheckInfo,
    registerSbpPaymentWithBinding,
    minAmountValue,
  };
};
