import { toJS } from 'mobx';
import { nanoid } from 'nanoid';
import { DEFAULT_IMAGE } from '~/components/Blocks/Templates/Softline/constants';
import { isExternal } from '~/utils/utils';
import {
  ImageDataModel,
  SoftlineSubscriptionsModel,
} from '~/stores/models/SoftlineModel';
import { Instance } from 'mobx-state-tree';
import { Feature } from '~/components/Blocks/Templates/Softline/types';

export const addAdditionalProperties = (
  list: Instance<typeof SoftlineSubscriptionsModel>[],
  features: Record<string, Feature>,
  imageSizes: Instance<typeof ImageDataModel>[],
  simIdFromBind?: number,
): Instance<typeof SoftlineSubscriptionsModel>[] => {
  const newSoftlineList = [];
  Object.keys(list).forEach((key) => {
    if (!(+key >= 0)) return;
    newSoftlineList.push({
      ...list[key],
      key: nanoid(5),
      imageInfo: imageSizes.find(
        (item) =>
          `${item.url}` === `${process.env.STATIC_SERVER}${DEFAULT_IMAGE}`,
      ),
    });

    Object.keys(features).forEach((feature) => {
      const softImg = features[feature]?.image;
      const imgUrl = isExternal(softImg)
        ? softImg
        : `${process.env.STATIC_SERVER}${softImg}`;
      const imageInfo = toJS(imageSizes)
        ? toJS(imageSizes).find((item) => item.url === imgUrl)
        : newSoftlineList[key].imageInfo;
      if (features[feature].metricId === list[key].metricId)
        newSoftlineList[key] = {
          ...newSoftlineList[key],
          ...features[feature],
          name: features[feature].name ?? newSoftlineList[key].name,
          nameFromMethod: newSoftlineList[key].name,
          footer: features[feature].footer,
          imageInfo,
        };
    });
  });
  /** Сортировка по sort в Mongo */
  const sortedList = [
    ...newSoftlineList
      .filter((item) => item.sort)
      .sort((a, b) => a.sort - b.sort),
    ...newSoftlineList.filter((item) => !item.sort),
  ];
  if (simIdFromBind) {
    sortedList.sort((a) => (a.simId !== simIdFromBind ? 1 : -1));
  }
  return sortedList;
};
