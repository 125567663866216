/** libraries */
import { FC, useEffect } from 'react';
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** styles */
import { StyledFooter } from '../../style';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useSettingsStore from '../../store/useSettingsStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
/** constants */
import { OPEN_ACCESS_WITHOUT_PASSWORD_ACTION } from '~/components/AuthWizard/constants';

const AllowAccessWithoutPasswordFooter: FC = () => {
  const {
    authStore: { isTemporaryTokenAuth, isAuth },
  } = useRootStore();
  const {
    allowAccessWithoutPasswordStore: {
      isLoginWithoutPassword,
      changeIpAuthAllow,
      isLoading,
      isDisableButton,
      password,
    },
  } = useSettingsStore();
  const {
    openSPAfterAuthorizationState,
    setOpenSPAfterAuthorizationState,
  } = useMakeAuthStore();

  useEffect(() => {
    if (!isAuth && !password) return;
    if (openSPAfterAuthorizationState === OPEN_ACCESS_WITHOUT_PASSWORD_ACTION) {
      changeIpAuthAllow(setOpenSPAfterAuthorizationState);
    }
  }, [isAuth]);

  return (
    <StyledFooter>
      <Button
        onClick={() => changeIpAuthAllow(setOpenSPAfterAuthorizationState)}
        disabled={isDisableButton || isTemporaryTokenAuth}
        loading={isLoading}
      >
        {isLoginWithoutPassword ? 'Разрешить' : 'Запретить'}
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(AllowAccessWithoutPasswordFooter);
