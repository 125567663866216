/* Библиотеки */
import * as React from 'react';
import { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
/* Компоненты */
import {
  Tag,
  defaultTheme,
  H3,
  Text,
  LeadingText,
  Button,
  ButtonStyleTypes,
  TabsStyleTypes,
  Tabs,
  LinkButton,
  Loader,
} from 'cordis-core-ui-planeta';
import {
  StyledProductSelectorItem,
  StyledProductSelectorInfo,
  StyledProductSelectorDescription,
  StyledTags,
  StyledChannelText,
} from './ProductSelectorItem.style';
import LinkWrapper from '~/components/LinkWrapper';
/* Интерфейсы */
import {
  ProductSelectorItemProps,
  TabNames,
} from './ProductSelectorItem.interfaces';
import { TagsProps, PriceInfoProps } from '../ProductTemplate.interfaces';
import { SummaryFields } from '~/components/Blocks/Templates/Summary/Summary.types';
import { AllowedTariffProps } from '~/components/Blocks/Templates/ProductSwitcher/interfaces';
/* Утилиты */
import { formatNumber, pluralizeAll } from '~/utils/utils';
import { getProductChangeErrorTexts } from '~/components/Blocks/Templates/ProductSwitcher/utils';
/* Константы */
import { CHANNELS_VARIANTS } from './ProductSelectItem.constants';
import { CALL_TO_ACTION_BLOCK_ID } from '~/components/Blocks/Templates/CallToAction/CallToAction.constants';
import { DEFAULT_ERROR, SERIES_CODE } from '~/constants/common';
/** api */
import { checkTariffSwitch, tariffCheckSwitchAgreement } from '~/api/apiPab2c';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';
import useContactsAndNotificationsStore from '../../ContactsAndNotifications/store/useContactsAndNotificationsStore';

/* Элементы переключения цены на продуктах с дневной оплатой */
const productSelectorItemTabs = (priceInfo: PriceInfoProps): JSX.Element => {
  // Стоимость обслуживания в день и за 30 дней
  const { daily, thirtyDays } = priceInfo;
  /* Варианты вкладок таба */
  const tabsValue = [...Object.values(TabNames)];
  /* Текущий индекс таба */
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
  /* Текущее значение таба */
  const [activeTabValue, setActiveTabValue] = React.useState<string>(
    `${formatNumber(daily)}\u0020\u20bd`,
  );
  /* Событие при изменении таба */
  const onChangeTab = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
    switch (tabIndex) {
      case 0:
        setActiveTabValue(`${formatNumber(daily)}\u0020\u20bd`);
        break;
      case 1:
        setActiveTabValue(`${formatNumber(thirtyDays)}\u0020\u20bd`);
        break;
      default:
        setActiveTabValue(`${formatNumber(daily)}\u0020\u20bd`);
        break;
    }
  };
  return (
    <>
      <Tabs
        value={tabsValue}
        onChange={onChangeTab}
        activeTabIndex={activeTabIndex}
        styleType={TabsStyleTypes.SECONDARY}
      />
      <H3>{activeTabValue}</H3>
    </>
  );
};

const discountPrices = (discount: number, price: number): JSX.Element => {
  return (
    <div className="action-discount">
      <Text color={defaultTheme.colors.planeta}>
        &#8722;&nbsp;{`${discount}%`}
      </Text>
      <Text color={defaultTheme.colors.gray}>{`${price}\u0020\u20bd`}</Text>
    </div>
  );
};

/* Дочерний компонент с карточкой продукта */
const ProductSelectorItem: React.FC<ProductSelectorItemProps> = ({
  tags,
  channelsInfo,
  isAnnual,
  marketingCategoryFeedLink,
  marketingName,
  marketingText,
  priceInfo,
  productFeedLink,
  seriesName,
  speedBaseText,
  isBusiness,
  tariffId,
  withoutFilters,
  action,
  setAgreement,
  setProductChangeError,
  tariff,
}: ProductSelectorItemProps) => {
  const {
    connectionTariffStore: { toggleChangeTariffWizardVisible },
    summaryDataStore: {
      seriesCode,
      annualPriceInfo,
      seriesName: currentSeriesName,
    },
    allowedTariffStore: { allowedTariff },
    authStore: { isAuth, auth, isTemporaryTokenAuth },
  } = useRootStore();
  const {
    haveVerifiedPhone,
    addingContactStore: { setIsShowAddingContact },
  } = useContactsAndNotificationsStore();
  /** Контекст подключения продукта */
  const { connectionWizardAuto } = useConnectionStore();

  // Общее количество каналов
  const { channelCount } = { ...channelsInfo };
  // Годовая стоимость обслуживания
  const { migrationCostFirst } = priceInfo;

  const annualPrice = useMemo(() => {
    if (action?.newPrice) return action.newPrice;
    /* Авторизованным пользователям показываем цену подключения годовых
     * с учетом пояса обслуживания
     */
    if (
      (seriesCode === SERIES_CODE.ONLINE_SUPER &&
        tariff.seriesCode === SERIES_CODE.ONLINE_SUPER_2_0) ||
      (seriesCode === SERIES_CODE.HIT_SUPER &&
        tariff.seriesCode === SERIES_CODE.HIT_SUPER_2_0)
    )
      return priceInfo.annual - annualPriceInfo;
    const costToMigration = allowedTariff.find(
      (item) => item.tariffTo.tariffId === tariffId,
    )?.price;

    return costToMigration || migrationCostFirst;
  }, [annualPriceInfo, priceInfo, seriesCode]);

  /** Загрузка соглашения */
  const [isLoadingAgreement, setIsLoadingAgreement] = useState<boolean>(false);

  /** Получение соглашения на подключение продукта */
  const getAgreement = async () => {
    try {
      const checkAgreement = await tariffCheckSwitchAgreement(tariff.tariffId);
      await checkTariffSwitch(tariffId);
      setAgreement(checkAgreement);
      toggleChangeTariffWizardVisible(tariff as SummaryFields);
    } catch (e) {
      const err = e?.errorMessage ? JSON.parse(e?.errorMessage) : null;
      toggleChangeTariffWizardVisible(tariff as SummaryFields);
      setProductChangeError(err?.Type);
      const productChangeErrorTexts = await getProductChangeErrorTexts(
        auth.contractName,
        currentSeriesName,
        seriesName,
        err.Extensions?.AgreementMessage,
      );
      setAgreement(productChangeErrorTexts[err?.Type] ?? DEFAULT_ERROR);
    } finally {
      setIsLoadingAgreement(false);
    }
  };

  /** Узнаем возможность подключения оптического продукта, для Product Switcher */
  const blockSwitchToFtth = useMemo(() => {
    return isAuth && allowedTariff
      ? ((allowedTariff as unknown) as AllowedTariffProps[])?.find(
          (item) => item?.tariffTo?.seriesCode === tariff.seriesCode,
        )?.tariffTo?.tariffMigrationRefuseReason === 'FtthError'
      : true;
  }, [isAuth, allowedTariff]);

  const isTransformer = tariff.seriesCode.includes('transformer');

  /** СП заявки на подключение */
  const connect = () => {
    return (
      <LinkWrapper
        href={
          productFeedLink ? `${productFeedLink}${CALL_TO_ACTION_BLOCK_ID}` : ''
        }
        target="_blank"
      >
        <Button
          minWidth="157px"
          background={defaultTheme.colors.light}
          styleType={ButtonStyleTypes.SECONDARY}
        >
          Выбрать
        </Button>
      </LinkWrapper>
    );
  };

  /** Продукт недоступен для перехода */
  const isNotAvailableProduct = useMemo(() => {
    if (!allowedTariff?.length) return false;
    return !((allowedTariff as unknown) as AllowedTariffProps[]).find(
      (item) => tariff.seriesCode === item.tariffTo.seriesCode,
    );
  }, [allowedTariff, tariff]);

  /** Обработчик кнопки смены типа подключения на оптику */
  const switchToFtthButtonHandler = async () => {
    if (isTemporaryTokenAuth) return;
    const havePhone = await haveVerifiedPhone();
    if (!havePhone) {
      setIsShowAddingContact(true, () =>
        connectionWizardAuto(
          tariff.seriesCode,
          tariff.seriesName,
          isAuth,
          auth,
        ),
      );
      return;
    }
    connectionWizardAuto(tariff.seriesCode, tariff.seriesName, isAuth, auth);
  };

  /** Подключение продукта */
  const connectButton = useMemo(() => {
    if (!isAuth) {
      return connect();
    }
    if (!allowedTariff) {
      return <Loader small />;
    }

    if (blockSwitchToFtth) {
      return (
        <Text
          className="ftth-text"
          lineHeight="24px"
          color={defaultTheme.colors.shadow}
        >
          <LinkButton
            onClick={switchToFtthButtonHandler}
            color={
              isTemporaryTokenAuth
                ? defaultTheme.colors.disable
                : defaultTheme.colors.planeta
            }
          >
            Подать заявку
          </LinkButton>
          <br />
          на подключение оптики
        </Text>
      );
    }
    return (
      <Button
        minWidth="157px"
        background={defaultTheme.colors.light}
        styleType={ButtonStyleTypes.SECONDARY}
        onClick={getAgreement}
        loading={isLoadingAgreement}
      >
        Сменить
      </Button>
    );
  }, [
    isAuth,
    blockSwitchToFtth,
    allowedTariff,
    isTemporaryTokenAuth,
    isNotAvailableProduct,
  ]);

  return (
    <StyledProductSelectorItem withoutFilters={withoutFilters}>
      <LinkWrapper href={productFeedLink}>
        <StyledProductSelectorDescription
          hasTags={tags?.length > 0}
          isBusiness={isBusiness}
        >
          <StyledTags>
            {tags?.length > 0 &&
              tags.map((item: TagsProps) => (
                <Tag
                  key={item.name}
                  colorTag={defaultTheme.colors.disable}
                  color={defaultTheme.colors.white}
                >
                  {item.name.toUpperCase()}
                </Tag>
              ))}
          </StyledTags>
          <H3 color={defaultTheme.colors.light}>{seriesName}</H3>
          {/* Присутствуют html теги и коды, white-space: pre-line не помогает */}
          <Text
            color={defaultTheme.colors.light}
            dangerouslySetInnerHTML={{ __html: marketingText }}
            lineHeight="24px"
          />
        </StyledProductSelectorDescription>
      </LinkWrapper>
      {/* чтобы в консоли не возникал warning вложенности ссылки в ссылке, ссылка категорий вынесена в position: absolute */}
      <div
        className={`marketingCategoryFeedLink ${
          seriesName.length > 13 && 'top'
        }`}
      >
        <LinkWrapper href={marketingCategoryFeedLink}>
          <Text
            color={
              isBusiness ? defaultTheme.colors.light : defaultTheme.colors.pink
            }
          >
            {marketingName}
          </Text>
        </LinkWrapper>
      </div>
      <StyledProductSelectorInfo>
        <LeadingText color={defaultTheme.colors.black}>
          {speedBaseText}
        </LeadingText>
        {channelsInfo && (
          <StyledChannelText
            isTransformer={isTransformer}
            color={defaultTheme.colors.black}
          >
            {isTransformer
              ? `Любые\nиз ${channelCount} каналов`
              : pluralizeAll(channelCount, CHANNELS_VARIANTS)}
          </StyledChannelText>
        )}
        <div className="price">
          {isAnnual ? (
            <>
              <Text>
                {tariff.priceInfo.annual === annualPrice
                  ? 'Подключение на год'
                  : 'Апгрейд продукта'}
                {action && <sup>*</sup>}
              </Text>
              <span className="price__annual">
                {action &&
                  !isAuth &&
                  discountPrices(action.discount, migrationCostFirst)}
                <H3>{`${formatNumber(annualPrice)}\u0020\u20bd`}</H3>
              </span>
            </>
          ) : (
            productSelectorItemTabs(priceInfo)
          )}
        </div>
        {connectButton}
      </StyledProductSelectorInfo>
    </StyledProductSelectorItem>
  );
};

export default observer(ProductSelectorItem);
