/** библиотеки */
import React, { FC, ReactNode } from 'react';
import { defaultTheme, LeadingText, Tag, Text } from 'cordis-core-ui-planeta';
import differenceInMonths from 'date-fns/differenceInMonths';

/** типы */
import { DevicesCardContentProps } from './interfaces';
import { ContractDevice } from '../../Templates/Pab2c/Devices/interfaces';

/** стилевые компоненты */
import {
  StyledBullet,
  StyledDevicesItem,
  StyledDevicesItemImage,
} from './style';

/* Утилиты */
import { formatNumber } from '~/utils/utils';
import { parseHtml } from '../Shared.utils';

/** константы */
import { DEFAULT_IMAGE, DEFAULT_IMAGE_SIZE, TAG_DEVICE } from './constants';
import {
  DEVICE_TYPES,
  OWNERSHIP_CODES,
  OWNERSHIP_MAP,
  PAYMENT_PERIOD_MAP,
} from '~/components/Blocks/Templates/Pab2c/Devices/constants';

/** компоненты */
import DeviceTabs from '~/components/Blocks/Templates/Devices/Components/DeviceTabs/DeviceTabs';

/**
 * Компонент с карточкой оборудования
 */
const DevicesCard: FC<DevicesCardContentProps> = ({
  item,
  onDeviceCardMouseUp,
  onDeviceCardMouseDown,
  isFixedWidth,
  isPab2cView,
  customTag,
  imageInfo,
}: DevicesCardContentProps) => {
  const { archive, name, typeName, vendorName, availabilityDt, gigabit } = item;

  // Возвращает тег для оборудования
  const generateDeviceTag = (): TAG_DEVICE | void => {
    if (gigabit) return TAG_DEVICE.GIGABIT;
    if (name === 'RT-GM2-9' && vendorName === 'Iskratel')
      return TAG_DEVICE.GPON;
    if (
      (name === 'UHD300X' && vendorName === 'Vermax') ||
      (name === 'UHD450Y2G Wi-Fi' && vendorName === 'Vermax')
    )
      return TAG_DEVICE.WIFI;
    // Проверка на то, что устройству менее 2 месяцев
    if (
      availabilityDt &&
      differenceInMonths(new Date(), new Date(availabilityDt)) < 2
    )
      return TAG_DEVICE.NEW;
    return null;
  };

  // Тег оборудования
  const deviceTag = generateDeviceTag();

  // Ссылка на изображение
  const imageSrc = imageInfo
    ? imageInfo?.url
    : `${process.env.STATIC_SERVER}${DEFAULT_IMAGE}`;

  const imgSizes = isPab2cView
    ? {
        newHeight: 126,
        newWidth: 126,
      }
    : {
        newHeight: imageInfo ? imageInfo?.height : DEFAULT_IMAGE_SIZE.height,
        newWidth: imageInfo ? imageInfo?.width : DEFAULT_IMAGE_SIZE.width,
      };

  const getFormOwning = (): ReactNode => {
    const ownershipKey = Object.keys(OWNERSHIP_MAP).find(
      (ownerKey) => ownerKey === (item as ContractDevice).ownership,
    );
    if (!ownershipKey) return null;
    return parseHtml(OWNERSHIP_MAP[ownershipKey]);
  };

  // todo: после добавления проверки состояния обслуживания, добавить цену при остановке обслуживания для аренды
  const getDevicePrice = (): number => {
    switch ((item as ContractDevice).ownership) {
      case OWNERSHIP_CODES.BOUGHT_LEASING:
        return item.annuity;
      case OWNERSHIP_CODES.LEASING:
      case OWNERSHIP_CODES.LEASING_WITH_OWNERSHIP:
        return (item as ContractDevice).priceOn;
      default:
        return null;
    }
  };

  const devicePrice = getDevicePrice();

  return (
    <StyledDevicesItem
      onMouseDown={onDeviceCardMouseDown}
      onMouseUp={onDeviceCardMouseUp}
      isFixedWidth={isFixedWidth}
      isPab2cView={isPab2cView}
      isArchive={archive || item?.isNotAvailable}
    >
      <div className="devices__item-image-wrapper">
        <StyledDevicesItemImage
          imgSrc={imageSrc}
          imgSizes={imgSizes}
          isPab2cView={isPab2cView}
        />
      </div>
      {(deviceTag || customTag) && (
        <div className="devices__item-tag">
          {deviceTag && (
            <Tag
              color={defaultTheme.colors.planeta}
              colorTag={defaultTheme.colors.pink}
              backgroundColor={defaultTheme.colors.white}
            >
              {deviceTag}
            </Tag>
          )}
          {customTag && (
            <Tag
              color={defaultTheme.colors.planeta}
              colorTag={defaultTheme.colors.pink}
              backgroundColor={defaultTheme.colors.white}
            >
              {customTag}
            </Tag>
          )}
        </div>
      )}
      <div className="devices__item-title">
        <Text lineHeight="24px">{`${
          typeName ?? DEVICE_TYPES[(item as ContractDevice)?.deviceTypeCode]
        }`}</Text>
        <div className="devices__item-title__name-block">
          <LeadingText color={defaultTheme.colors.black}>
            {vendorName
              ? `${vendorName} ${name}`
              : name ?? (item as ContractDevice).modelName}
          </LeadingText>
          {!isPab2cView && item?.colors?.length > 0 && (
            <div className="devices__color-bullets">
              {item?.colors.map((color) => {
                return <StyledBullet hex={color.hex} key={color.hex} />;
              })}
            </div>
          )}
        </div>
      </div>
      {!archive && !item?.isNotAvailable && !isPab2cView && (
        <DeviceTabs item={item} orderEquipment={onDeviceCardMouseUp} />
      )}
      {(archive || item?.isNotAvailable) && !isPab2cView && (
        <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
          Недоступно для заказа
        </Text>
      )}
      {isPab2cView && (
        <span className="devices__item-ownership">
          <Text color={defaultTheme.colors.shadow}>{getFormOwning()}</Text>
        </span>
      )}

      {isPab2cView && (
        <span className="devices__item-payment">
          {(item as ContractDevice).ownership !== OWNERSHIP_CODES.BOUGHT &&
            devicePrice > 0 && (
              <>
                <Text>
                  {formatNumber(devicePrice)} ₽ {PAYMENT_PERIOD_MAP.daily}
                </Text>
              </>
            )}
          {(item as ContractDevice).warrantyPlusInfo?.isOrdered && (
            <Tag
              colorTag={defaultTheme.colors.green}
              color={defaultTheme.colors.green}
            >
              ГАРАНТИЯ&nbsp;&#43;
            </Tag>
          )}
        </span>
      )}
    </StyledDevicesItem>
  );
};

export default DevicesCard;
