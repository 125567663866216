/** библиотеки */
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

/** стили */
import { StyledMaintenanceWork, StyledProductSwitcherWizard } from './style';

/** компоненты библиотеки */
import {
  defaultTheme,
  PriceTag,
  Button,
  PriceTagBackgroundColor,
  ButtonStyleTypes,
  Icon,
  Icons,
  H2,
  H3,
  Loader,
  Text,
  LeadingText,
  SidePage,
} from 'cordis-core-ui-planeta';

/** stores */
import { useRootStore } from '~/stores/RootStore';

/** интерфейсы */
import { ProductSwitcherWizardProps } from './types';
import { TariffProps } from '../interfaces';

/** компоненты */
import LinkWrapper from '~/components/LinkWrapper';
import Portal from '~/components/Portal/Portal';
import ProductSwitcherWizardFooter from './ProductSwitcherWizardFooter';

/** константы */
import { desktop1100, desktop940 } from '~/components/Grid/constants';

/** утилиты */
import { formatNumber } from '~/utils/utils';
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
import { dateString } from '../../Pab2c/Contract/utils';
import { PRODUCT_CHANGE_ERRORS } from '../constants';

/**
 * Блок ProductSwitcher. SidePage подключения/смены продукта.
 */
const ProductSwitcherWizard: FC<ProductSwitcherWizardProps> = ({
  tariff,
  result,
  agreement,
  productChangeError,
  setProductChangeError,
  handleSetTariff,
  connectLoading,
}: ProductSwitcherWizardProps) => {
  const {
    authStore: { auth },
    connectionTariffStore: { toggleChangeTariffWizardVisible },
    summaryDataStore: { summaryData },
    summaryDataStore: { getSummariesByTariffIds },
    allowedTariffStore: { getTariffAllowed },
  } = useRootStore();

  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  /** Разница между балансом и ценой оборудования */
  const differenceBetweenBalanceAndConnectionPrice =
    auth.balance - (tariff as TariffProps)?.price ?? 0;

  const maintainceWork = () => {
    return (
      <StyledMaintenanceWork>
        <Icons.BlockedIcon />
        {isDesktop940 ? (
          <H2>Смена продукта временно недоступна</H2>
        ) : (
          <LeadingText color={defaultTheme.colors.black}>
            Смена продукта временно недоступна
          </LeadingText>
        )}
        <div className="yellow-block">
          <Text lineHeight="24px">
            Возможность смены продукта недоступна на время проведения
            технических работ. <br /> Повторите попытку позднее.
          </Text>
        </div>
        <Text lineHeight="24px">
          Остальные функции раздела «Моя Планета» доступны в полном объёме.
        </Text>
      </StyledMaintenanceWork>
    );
  };

  /** Текст на экране результата */
  const resultText = 'Заявка на смену продукта\nуспешно отправлена';

  const isSuccessAgreement = !productChangeError;

  const isNotEnoughMoney =
    productChangeError === PRODUCT_CHANGE_ERRORS.NOT_ENOUGH_MONEY;

  /** Профилактические работы. ЛК не доступен */
  const isMaintenanceWork =
    productChangeError === PRODUCT_CHANGE_ERRORS.ERROR_MAINTENANCE;

  const updateSummary = async () => {
    await getSummariesByTariffIds();
    await getTariffAllowed();
  };

  return (
    <Portal>
      <SidePage
        show={!!tariff}
        headerText={
          tariff && !result.isResult && !isMaintenanceWork
            ? `Подключение продукта${'\u00A0'}${tariff.seriesName}`
            : ''
        }
        onCloseClick={() => {
          toggleChangeTariffWizardVisible(null);
          setProductChangeError('');
          if (result.isResult && result.isCorrect) {
            updateSummary();
          }
        }}
        footerContainer={
          !result.isResult && (
            <ProductSwitcherWizardFooter
              tariff={tariff}
              tariffConnect={() => handleSetTariff()}
              alreadyConnected={!!summaryData}
              agreement={agreement}
              isLoading={connectLoading}
              productChangeError={productChangeError}
            />
          )
        }
        removeScrollBar={false}
      >
        {isMaintenanceWork ? (
          maintainceWork()
        ) : (
          <StyledProductSwitcherWizard>
            {result.isResult && result.isCorrect && (
              <>
                <div className="product-change__result-icon">
                  <Icon icon={<Icons.SuccessIconAnimated />} />
                </div>
                {isDesktop1100 ? <H2>{resultText}</H2> : <H3>{resultText}</H3>}
              </>
            )}

            {result.isResult && !result.isCorrect && (
              <>
                <div className="product-change__result-icon">
                  <Icon icon={<Icons.NotOkBigIcon />} />
                </div>
                {isDesktop1100 ? (
                  <H2>{result.text}</H2>
                ) : (
                  <H3>{result.text}</H3>
                )}
              </>
            )}

            {!result.isResult && (
              <>
                {(tariff as TariffProps)?.price > 0 &&
                  (isSuccessAgreement || isNotEnoughMoney) && (
                    <div className="product-change__block-wrapper">
                      <div className="product-change__block-wrapper__price-tag">
                        <PriceTag
                          header="Стоимость подключения"
                          value={`${formatNumber(
                            (tariff as TariffProps)?.price,
                          )}₽`}
                          backgroundColor={
                            differenceBetweenBalanceAndConnectionPrice > 0
                              ? PriceTagBackgroundColor.OK
                              : PriceTagBackgroundColor.WARNING
                          }
                          subscription={
                            differenceBetweenBalanceAndConnectionPrice > 0
                              ? `На вашем${'\u000A'}счету ${formatNumber(
                                  auth.balance,
                                )} ₽`
                              : `Не хватает${'\u000A'}${formatNumber(
                                  Math.abs(
                                    differenceBetweenBalanceAndConnectionPrice,
                                  ),
                                )} ₽`
                          }
                          fontColor={
                            differenceBetweenBalanceAndConnectionPrice > 0
                              ? defaultTheme.colors.black
                              : defaultTheme.colors.warning
                          }
                          headerType="H2"
                        />
                        {differenceBetweenBalanceAndConnectionPrice < 0 && (
                          <LinkWrapper href="/payment">
                            <Button styleType={ButtonStyleTypes.SECONDARY}>
                              {!isDesktop1100
                                ? 'Пополнить'
                                : 'Пополнить баланс'}
                            </Button>
                          </LinkWrapper>
                        )}
                      </div>
                    </div>
                  )}

                {(isSuccessAgreement || isNotEnoughMoney) && (
                  <div className="product-change__date">
                    <div className="product-change__date__block">
                      <Text
                        lineHeight="24px"
                        color={defaultTheme.colors.shadow}
                      >
                        Дата подключения Продукта
                      </Text>
                      <LeadingText color={defaultTheme.colors.black}>
                        {dateString(new Date(), 'd MMMM')}
                      </LeadingText>
                    </div>
                  </div>
                )}

                <div className="product-change__agreement">
                  {agreement ? (
                    <>
                      {isSuccessAgreement && (
                        <Text
                          className="product-change__agreement__header"
                          lineHeight="24px"
                          fontWeightBold
                        >
                          Согласие на подключение продукта «{tariff?.seriesName}
                          »
                        </Text>
                      )}
                      {parseHtml(agreement)}
                    </>
                  ) : (
                    <div className="loader">
                      <Loader />
                    </div>
                  )}
                </div>
              </>
            )}
          </StyledProductSwitcherWizard>
        )}
      </SidePage>
    </Portal>
  );
};

export default observer(ProductSwitcherWizard);
