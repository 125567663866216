/** библиотеки */
import React, { FC } from 'react';
import { observer } from 'mobx-react';

/** типы */
import { DevicesContentProps } from './interfaces';

/** stores */
import { DeviceStoreProvider } from './store/useDeviceStore';
import Main from './Main';

/**
 * Блок "Агрегатор оборудования"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=525730789
 * @param content
 */
const Devices: FC<DevicesContentProps> = ({ content }: DevicesContentProps) => {
  const { fields } = content;
  return (
    <DeviceStoreProvider fields={fields}>
      <Main />
    </DeviceStoreProvider>
  );
};

export default observer(Devices);
