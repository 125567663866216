import { createContext, useContext } from 'react';
import DeviceDetailedCardModel from './DeviceDetailedCardStore';
import { defaultModelState } from '~/stores/models/createApiPathModel';
import { DEFAULT_RESULT } from '~/constants/common';
import { DEFAULT_SELECT } from '~/components/Blocks/Templates/Devices/constants';
import { Instance } from 'mobx-state-tree';

const store = DeviceDetailedCardModel.create({
  requestsState: { createDemand: defaultModelState },
  isDetailedDeviceShow: false,
  detailedDeviceData: null,
  phone: '',
  result: DEFAULT_RESULT,
  error: '',
  select: DEFAULT_SELECT,
  colorSelect: DEFAULT_SELECT,
});
type IDeviceDetailedCardStore = Instance<typeof DeviceDetailedCardModel>;

const StoreContext = createContext<IDeviceDetailedCardStore>(store);

const useDeviceDetailedCardStore = () => {
  return useContext(StoreContext);
};

export default useDeviceDetailedCardStore;
